import React from "react";
import $ from "jquery";

class FooterLinks extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="row justify-content-center footerRow">
        <div className="right-side authFooter  d-flex align-self-center justify-content-center">
          <div className="inner-container  d-flex align-self-center justify-content-center">
            <div className="text text-center">
              {this.props.content}
              <div className="text themeTxtColor loginReg">
                <a href={this.props.link} className="inheritColor">
                  {this.props.linktext}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FooterLinks;
