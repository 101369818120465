import React from "react";
import $ from "jquery";
import MessageContainer from "./MessageContainer";
import Loader from "react-loader-spinner";
import FooterLinks from "./footerlinks";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ApiPostCall } from "../Functions/connector";

class CompleteRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsRegistrationComplete: false,
      CnfPasswordType: "password",
      PasswordType: "password",
    };
  }
  OnlyFourNumber(e) {
    const cardNum = e.target.value;
    if (cardNum.length > 4) {
      const oldValue = cardNum.substring(0, 4);
      $("#cardNumber").val(oldValue);
    }
  }
  ShowPassword(e) {
    this.setState({
      IsShowPassword: true,
      PasswordType: "text",
    });
  }
  HidePassword(e) {
    this.setState({
      IsShowPassword: false,
      PasswordType: "password",
    });
  }
  ShowPasswordCnf(e) {
    this.setState({
      IsShowPasswordCnf: true,
      CnfPasswordType: "text",
    });
  }
  HidePasswordCnf(e) {
    this.setState({
      IsShowPasswordCnf: false,
      CnfPasswordType: "password",
    });
  }

  FilterErrorType(ErrorType) {
    console.log("errorTP");
    if (ErrorType == "InvalidRentalCode") {
      $(".generalError").empty().text("Invalid Rental Code!");
    } else if (ErrorType == "InvalidCardNumber") {
      $(".generalError").empty().text("Invalid Card Number!");
    } else if (ErrorType == "BadRequest") {
      $(".generalError")
        .empty()
        .text("Invalid Data. Please check your details again!");
    } else if (ErrorType == "EmailExist") {
      $(".generalError").empty().text("Email address already exist!");
    } else if (ErrorType == "CantRegister") {
      $(".generalError")
        .empty()
        .text("Some error occured in system. Please try again after sometime!");
    } else if (ErrorType == "NoFindDetails") {
      $(".generalError")
        .empty()
        .text("We could not find your details. Please check and try again!");
    } else {
      $(".generalError").empty().text("Some error occured in system!");
    }
  }

  ValidateFields(rentalCode, cardNumber, passwrod, cnfpasswrod) {
    if (
      rentalCode == "" ||
      cardNumber == "" ||
      passwrod == "" ||
      cnfpasswrod == ""
    ) {
      $(".generalError").text("All field are required");
      return false;
    }
    if (cardNumber.length != 4) {
      $("#cardNumber")
        .parent()
        .find(".errorText")
        .text("Please enter 4 digit card number!");
      return false;
    } else {
      $("#cardNumber").parent().find(".errorText").text("");
    }
    if (passwrod.length < 8 || passwrod.length > 16) {
      $("#RegPassword")
        .parent()
        .find(".errorText")
        .text("Password should be between 8-16 characters!");
      return false;
    } else {
      $("#RegPassword").parent().find(".errorText").text("");
    }
    if (passwrod != cnfpasswrod) {
      $("#RegPasswordCnf")
        .parent()
        .find(".errorText")
        .text("Password not matched!");
      return false;
    } else {
      $("#RegPasswordCnf").parent().find(".errorText").text("");
      return true;
    }
  }
  CompleteReg = async () => {
    $(".generalError").text("");
    const rentalCode = $("#rentalCode").val().trim();
    const cardNumber = $("#cardNumber").val().trim();
    const passwrod = $("#RegPassword").val().trim();
    const cnfpasswrod = $("#RegPasswordCnf").val().trim();

    if (!this.ValidateFields(rentalCode, cardNumber, passwrod, cnfpasswrod)) {
      return;
    }
    var urlencoded = new URLSearchParams();
    urlencoded.append("UserEmail", this.props.validEmail);
    urlencoded.append("Password", passwrod);
    urlencoded.append("RentalCode", rentalCode);
    urlencoded.append("CardNumber", cardNumber);

    this.setState({ isLoader: true });

    await ApiPostCall("/Auth/UserRegister", urlencoded).then((result) => {
      this.setState({ isLoader: false });
      if (result == undefined || result == "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        // console.log(responseRs);
        if (responseRs.hasOwnProperty("Error")) {
          const ErrorType = responseRs.Error;
          this.FilterErrorType(ErrorType);
        } else {
          this.setState({
            IsRegistrationComplete: true,
          });
        }
      }
      this.setState({ isLoader: false });
    });
  };

  render() {
    const registrationDoneTxt =
      "You have registered to our system successfully. Now you can sign in to our system with your email address and password.";
    return (
      <>
        {this.state.IsRegistrationComplete && (
          <MessageContainer
            title="Registration Success"
            ButtonText="Sign In"
            content={registrationDoneTxt}
          />
        )}
        {!this.state.IsRegistrationComplete && (
          <form>
            <div className="row m-0 loginPageBody">
              <div className="card col-lg-4 m-auto border-0 ">
                <div className="row  p-1">
                  <div className="col-12 d-flex justify-content-center align-self-center">
                    <img src="/Tns-Logo.png" />
                  </div>
                  <div className="col-12">
                    <div className="card mt-4 border-0 authCard p-4">
                      <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                        Complete Registration
                      </div>

                      <div className="form-group ">
                        <label htmlFor="emailAddess">Email Address</label>
                        <p>{this.props.validEmail}</p>
                      </div>
                      <div className="form-group">
                        <label htmlFor="rentalCode">Rental Code</label>
                        <input
                          type="number"
                          className="form-control"
                          id="rentalCode"
                          placeholder="XX-XXXX"
                        />
                        <p className="errorText"></p>
                      </div>
                      <div className="form-group">
                        <label htmlFor="cardNumber">
                          Last 4 digits of credit card
                        </label>
                        <input
                          type="number"
                          onKeyUp={this.OnlyFourNumber.bind(this)}
                          className="form-control"
                          id="cardNumber"
                          placeholder="XXXX"
                        />
                        <p className="errorText"></p>
                      </div>
                      <div className="form-group parent passwordContainer">
                        <label htmlFor="RegPassword">Password</label>
                        <input
                          type={this.state.PasswordType}
                          className="form-control"
                          id="RegPassword"
                          placeholder="New-Password"
                        ></input>
                        {!this.state.IsShowPassword && (
                          <span
                            className="passwordShow"
                            onClick={this.ShowPassword.bind(this)}
                          >
                            <FaEye className="inputIcon" role="button" />
                          </span>
                        )}
                        {this.state.IsShowPassword && (
                          <span
                            className="passwordHide"
                            onClick={this.HidePassword.bind(this)}
                          >
                            <FaEyeSlash className="inputIcon" role="button" />
                          </span>
                        )}
                        <p className="errorText"></p>
                      </div>
                      <div className="form-group parent CnfPwdContainer">
                        <label htmlFor="RegPasswordCnf">Confirm Password</label>
                        <input
                          type={this.state.CnfPasswordType}
                          className="form-control"
                          id="RegPasswordCnf"
                          placeholder="New-Password"
                        />
                        {!this.state.IsShowPasswordCnf && (
                          <span
                            className="passwordShow"
                            onClick={this.ShowPasswordCnf.bind(this)}
                          >
                            <FaEye className="inputIcon" role="button" />
                          </span>
                        )}
                        {this.state.IsShowPasswordCnf && (
                          <span
                            className="passwordHide"
                            onClick={this.HidePasswordCnf.bind(this)}
                          >
                            <FaEyeSlash className="inputIcon" role="button" />
                          </span>
                        )}
                        <p className="errorText"></p>
                      </div>
                      <button
                        type="button"
                        onClick={this.CompleteReg}
                        className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                      >
                        Sign Up
                        {this.state.isLoader && (
                          <span className="loaderCustom">
                            {" "}
                            <Loader
                              type="Oval"
                              color="#142454"
                              height={25}
                              width={25}
                              timeout={100000}
                            />
                          </span>
                        )}
                      </button>
                      <p className="errorText generalError"></p>
                    </div>
                  </div>
                </div>
              </div>
              <FooterLinks
                content={"Have an account?"}
                link={"/login"}
                linktext={"Sign In Now!"}
              />
            </div>
          </form>
        )}
      </>
    );
  }
}
export default CompleteRegister;
