import React from "react";
import Topbar from "../Oraganism/topbar";
import Sidebar from "../Oraganism/sidebar";
import GeneralInfoCard from "./generalInfoCard";
import RentalCarousel_New from "../Slider/RentalCarousel_New";
import "./rentalcss.css";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rentals: [],
    };
  }
  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const token = window.localStorage.getItem("Token");
      this.setState({ rentals: foundUser[0] });
    } else {
      window.location.href = "/login";
    }
  }
  render() {
    return (
      <>
        <div className="pageMargin">
          <div className="row ">
            <div className="rentalContainer  col-lg-12 p-0">
              {/* <div className="cardHeader col-12 mb-2">Your Rentals</div> */}
              <div className="sliderBody">
                {/* Old Carousel
                <RentalCarousel /> */}
                <RentalCarousel_New />
              </div>
            </div>
          </div>

          {/* <div className="row mobileRentalContainer">
            <div className="rentalContainer  col-lg-12 p-0">
              <div className="sliderBody">
                <RentalMobileCarousel />
              </div>
            </div>
          </div> */}

          <div className="row mt-1">
            <div className="invoiceContainer col-lg-6 ">
              <GeneralInfoCard
                isFreezeSim="True"
                title="Lost your sim?"
                buttonLink="#"
                buttonText="Freeze Now!"
                bgImage="/images/simLostGirl.png"
                body="Click on the button below to report a lost or stolen sim."
                class="FreezeSim_mt"
                paddingTop="pt-5"
                gCard="gCard"
                gContainer="gContainer"
              />
            </div>
            <div className="invoiceContainer mtopM col-lg-6 ">
              <GeneralInfoCard
                title="Moving to Israel?"
                buttonLink="http://tcsisrael.com/"
                targetValue="_blank"
                buttonText="Learn More"
                bgImage="/images/movingIsraelGirl.png"
                body="TCS provides all of your communication needs in Israel, including fiber, mobile phone, home phone, internet, TV, and virtual phone numbers."
                paddingTop="paddingTopDashboard"
                gCard="gCard"
                gContainer="gContainer"
              />
            </div>
            {/* <div className=" col-lg-6 mb-4 ">
                <ReactSlider/>
              </div> */}
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
