import React from "react";
import "./payment.css";
import $ from "jquery";
class deleteCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancelled: false,
    };
  }
  Dismiss = () => {
    $(".deleteCardDismissBtn").find("#closebtn2").click();
  };

  confirm2 = () => {
    this.setState({ isCancelled: true });
  };
  render() {
    return (
      <>
        {!this.state.isCancelled && (
          <div>
            <div className="row p-2 DCfont1 justify-content-center">
              Do you want remove this card?
            </div>
            <div className="mt-5">
              <div className="row DCfont2">Card No.</div>
              <div className="row DCfont3">
                {`XXXX - XXXX - XXXX - ${this.props.cnumber}`}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-6 col-md-6 col-6 ">
                <button className="dismissOSbtn" onClick={this.Dismiss}>
                  Dismiss
                </button>
              </div>

              <div className="col-lg-6 col-md-6 col-6 ">
                <button className="confirmbtn" onClick={this.confirm2}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {this.state.isCancelled && (
          <div>
            <div className="row p-2 DCfont1 justify-content-center">
              Card Deleted
            </div>
            <div className="mt-5">
              <div className="row DCfont2">Card No.</div>
              <div className="row DCfont3">
                {`XXXX - XXXX - XXXX - ${this.props.cnumber}`}
              </div>
            </div>
            <div className="row mt-4">
              <div className="row mt-4 centerdismissBtn">
                <button className="dismissDCbtn" onClick={this.Dismiss}>
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
export default deleteCard;
