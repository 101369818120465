import React from "react";
import "./generalInfoCard.css";
import "./freezeSimModal.css";
import PopupModal from "./Popup";
import AllDetailsSaved from "./allDetailsSaved";
import FreezeSimModalNew from "./FreezeSimModalNew.jsx";
class GeneralInfoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={`${this.props.gCard} p-3`}>
        <div
          className={this.props.gContainer}
          style={{ backgroundImage: `url('${this.props.bgImage}')` }}
        >
          <div className="gTitle pb-1">{this.props.title}</div>
          <div className="gBody  pb-1">{this.props.body}</div>
          <div className={`gButton ${this.props.paddingTop}`}>
            {!this.props.isFreezeSim && (
              <a
                className="btn btn-primary"
                href={this.props.buttonLink}
                target={this.props.targetValue}
              >
                {this.props.buttonText}
              </a>
            )}

            {this.props.isFreezeSim && (
              <>
                <PopupModal
                  class={this.props.class}
                  btnName={this.props.buttonText}
                  size="middleSize mobile freezeSimModal"
                >
                  <FreezeSimModalNew ProviderCodes={this.props.ProviderCodes} />
                </PopupModal>

                {/* <div className="modalClass undefined">
                  <button type="button" className="mt-1 btn btn-primary">
                    Freeze Now!
                  </button>
                </div> */}
              </>
            )}
            <PopupModal
              id="popup"
              className="popup popupsize desktop "
              size="popupsize"
            >
              <AllDetailsSaved isbtnSim="False" />
            </PopupModal>
          </div>
        </div>
      </div>
    );
  }
}

export default GeneralInfoCard;
