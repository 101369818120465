import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChangePasswordValidation } from "../Yup Validation/YupValidations";

export default function ChangePassword(props) {
  const [ShowHide, setShowHide] = useState({
    OldPasswordType: "password",
    CnfPasswordType: "password",
    NewPasswordType: "password",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ChangePasswordValidation),
  });

  const submitFormPassword = (data) => {
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Password", data.password);
    urlencoded.append("NewPassword", data.npassword);
    urlencoded.append("Token", token);
    ApiPostCall("/Profile/changeSecurity", urlencoded).then((result) => {
      // console.log("got it", result);
      var results = result;
      // console.log(results);
      if (results == '"IncorrectCurrentPassword"') {
      console.log("incorrect password");

        $(".successMsg").text("Please enter correct password.");
      }

      if (results == '"success"') {
        $(".pwdChangeBtn").attr("disabled", "true");
        console.log("correct password");

        $(".successMsg").text("Your password has been changed successfully!");
        const encodedString = base64_encode(
          `${props.accountemail}:${data.npassword}`
        );
        window.localStorage.setItem("Token", encodedString);
      }
    });
  };

  return (
    <div>
      <div className="col-lg-4 p-4">
        <form onSubmit={handleSubmit(submitFormPassword)}>
          <div className="form-group mt-2">
            <label htmlFor="input-1">Current Password</label>
            <input
              type={ShowHide.OldPasswordType}
              name="password"
              className="form-control"
              id="password"
              placeholder="Password"
              {...register("password")}
            />
            {ShowHide.OldPasswordType == "password" ? (
              <>
                <FaEyeSlash
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.OldPasswordType == "password" &&
                      setShowHide({ ...ShowHide, OldPasswordType: "text" });
                  }}
                />
              </>
            ) : (
              <>
                <FaEye
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.OldPasswordType == "text" &&
                      setShowHide({ ...ShowHide, OldPasswordType: "password" });
                  }}
                />
              </>
            )}

            <p className="text-danger"> {errors.password?.message} </p>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="input-2">New Password</label>
            <input
              type={ShowHide.NewPasswordType}
              name="npassword"
              className="form-control"
              id="npasswoed"
              placeholder="New Password"
              {...register("npassword")}
            />
            {ShowHide.NewPasswordType == "password" ? (
              <>
                <FaEyeSlash
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.NewPasswordType == "password" &&
                      setShowHide({ ...ShowHide, NewPasswordType: "text" });
                  }}
                />
              </>
            ) : (
              <>
                <FaEye
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.NewPasswordType == "text" &&
                      setShowHide({ ...ShowHide, NewPasswordType: "password" });
                  }}
                />
              </>
            )}
            <p className="text-danger"> {errors.npassword?.message} </p>
          </div>
          <div className="form-group mt-2">
            <label htmlFor="input-3">Confirm Password</label>
            <input
              type={ShowHide.CnfPasswordType}
              name="cpassword"
              className="form-control"
              id="cpassword"
              placeholder="Confirm Password"
              {...register("cpassword")}
            />
            {ShowHide.CnfPasswordType == "password" ? (
              <>
                <FaEyeSlash
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.CnfPasswordType == "password" &&
                      setShowHide({ ...ShowHide, CnfPasswordType: "text" });
                  }}
                />
              </>
            ) : (
              <>
                <FaEye
                  className="inputIcon"
                  role="button"
                  onClick={() => {
                    ShowHide.CnfPasswordType == "text" &&
                      setShowHide({ ...ShowHide, CnfPasswordType: "password" });
                  }}
                />
              </>
            )}

            <p className="text-danger">
              {errors.cpassword?.type === "oneOf"
                ? "New Password and Confirm Password must be same."
                : errors.cpassword?.message}
            </p>
          </div>
          <div className="form-group">
            <button
              type="submit"
              value="Submit"
              className="form-control btn btn-primary px-5 mt-3 pwdChangeBtn"
            >
              Update Password
            </button>
          </div>
          <div className="mt-2 successMsg"></div>
        </form>
      </div>
    </div>
  );
}
