import React from "react";
import "./invoice&usageCss.css";
import $ from "jquery";
import DataTable from "../Oraganism/DataTable";
class Invoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFilter: "filterContainerMobile",
      rentals: [],
      getrentalC: "0",
      FromDate: "",
      ToDate: "",
      fdate: 0,
      tdate: 0,
    };
  }

  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      this.setState({ rentals: foundUser[0] });
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let rentalc = params.rc;
    let fDATE = params.fd;
    let tDATE = params.td;
    this.setState({ getrentalC: rentalc });
    this.setState({ FromDate: fDATE });
    this.setState({ ToDate: tDATE });
  }

  handleChangePhoneNo(e) {
    this.setState({ getrentalC: e.target.value });
  }

  handleChangeFromDate(e) {
    this.setState({ FromDate: e.target.value });
  }
  handleChangeToDate(e) {
    this.setState({ ToDate: e.target.value });
  }

  filterSwap = () => {
    this.state.mobileFilter == ""
      ? this.setState({ mobileFilter: "filterContainerMobile" })
      : this.setState({ mobileFilter: "" });
  };

  Filter = () => {
    var x = document.getElementById("fromCalender").value;
    var y = document.getElementById("toCalender").value;
    // var m = document.getElementById("select").value;
    var m = "0";
    var n = document.getElementById("phoneSelect").value;

    window.location.href =
      "/invoice?fd=" + x + "&td=" + y + "&st=" + m + "&rc=" + n;
  };

  render() {
    var renCodes = [];

    for (var i = 0; i < this.state.rentals.length; i++) {
      if (this.state.rentals[i].BalanceDue > 0) {
        renCodes.push(
          <option key={i} value={this.state.rentals[i].RentalCode}>
            {this.state.rentals[i].PhoneNumber}
          </option>
        );
      }
    }
    return (
      <>
        <div className=" row  ">
          <div className=" Mobile">
            <div
              className={`mobile topHeader mleft ${this.state.mobileFilter == "" ? "d-none" : ""
                } `}
            >
              Invoice
              <i
                className="fa fa-solid fa-filter pullRight mRight pt-2"
                onClick={this.filterSwap}
              ></i>
            </div>
          </div>
          {/* <form onSubmit={this.handleSubmit}> */}
          <div className=" col-lg-12 col-md-12 col-12">
            <div className={`row filterContainer  ${this.state.mobileFilter} `}>
              <div className="col-10 mobile">
                <div className="filterInvoice mobile">Filter Invoice</div>
              </div>
              <div className="col-2 closebtn mobile" onClick={this.filterSwap}>
                x
              </div>
              <div className="col-lg col-md col-12">
                <div className="fromDate">From</div>
                <div className="" title="Select start date">
                  <input
                    className="fromCalender"
                    name="begin Calender"
                    type="date"
                    id="fromCalender"
                    value={this.state.FromDate || ""}
                    onChange={this.handleChangeFromDate.bind(this)}
                  />

                  {/* <div className="text-danger">
                      {this.state.errors.fromDate}
                    </div> */}
                </div>
              </div>
              <div className="col-lg col-md col-12">
                <div className="toDate">To</div>
                <div className="" title="Select end date">
                  <input
                    className="toCalender"
                    type="date"
                    id="toCalender"
                    name="Calender"
                    value={this.state.ToDate || ""}
                    onChange={this.handleChangeToDate.bind(this)}
                  />
                  {/* <div className="text-danger">
                      {this.state.errors.toDate}
                    </div> */}
                </div>
              </div>
              {/* <div className="col-lg col-md col-12">
                <div className="statusData">Status</div>
                <div className="">
                  <select
                    className="form-select statusbtn"
                    aria-label="Default select example"
                    id="select"
                  >
                    <option
                      className=""
                      value="-1"
                      // onChange={this.handleChange}
                    >
                      Please Select
                    </option>
                    <option value="0">Paid</option>
                    <option value="1">Due</option>
                  </select>
                 
                </div>
              </div> */}
              <div className="col-lg col-md col-12">
                <div className="phoneData">Phone Number</div>
                <div className="" title="Select Phone Number">
                  <select
                    className="form-select phonebtn "
                    aria-label="Default select example"
                    id="phoneSelect"
                    value={this.state.getrentalC || ""}
                    onChange={this.handleChangePhoneNo.bind(this)}
                  >
                    <option value="0">All</option>
                    {renCodes}
                  </select>
                  {/* <div className="text-danger">{this.state.errors.pNum}</div> */}
                </div>
              </div>
              <div className="col-lg col-md col-12">
                <button
                  type="submit"
                  value="Submit"
                  className="btn btn applybtn"
                  onClick={this.Filter}
                >
                  <div className="applyFont" title="Apply Filters">
                    Apply Filters
                  </div>
                </button>
              </div>
            </div>
          </div>
          {/* </form> */}
        </div>

        <DataTable />
      </>
    );
  }
}

export default Invoice;
