import React from "react";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import { List } from "reactstrap";

class virtualNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      VRisCancelled: false,
      erMonth: [],
      errors: {},
      KntCountries: [],
      errorMessage: "",
      iftrue: true,
    };
  }

  componentDidMount() {
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/KntCountry", urlencoded).then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        const responseRs2 = JSON.parse(responseRs);
        console.log(responseRs2[0]);
        this.setState({ KntCountries: responseRs2[0] });
      }
    });
  }

  ERconfirm2 = () => {
    var datagb = $("#VRselect").val();
    var kntLt = $("#VRselect option:selected").attr("kntlt");
    var kntSt = $("#VRselect option:selected").attr("kntst");
    var kntSfl = $("#VRselect option:selected").attr("kntsfl");
    let isValid = true;
    let errors = {};
    var actualKntCode = "";
    if (this.props.RentalCode.KNTPlanType == "LT") {
      actualKntCode = kntLt;
    } else if (this.props.RentalCode.KNTPlanType == "ST") {
      actualKntCode = kntSt;
    } else {
      actualKntCode = kntSfl;
    }
    console.log(actualKntCode);
    if (datagb == "0") {
      this.setState({ VRisCancelled: false });
      errors["adData"] = "Please Select Country";
      this.setState({
        errors: errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }

    if (isValid) {
      const token = window.localStorage.getItem("Token");

      var urlencoded = new URLSearchParams();
      urlencoded.append("KntCode", actualKntCode);
      urlencoded.append("RentalCode", this.props.RentalCode.RentalCode);
      urlencoded.append("PhoneNumber", this.props.RentalCode.PhoneNumber);
      urlencoded.append("Token", token);
      ApiPostCall("/RouterParser/AddVirtualNumber", urlencoded).then(
        (result) => {
          console.log("got it" + result);
          var ifTrue = result
            .split(/[\s,\?\,\.!]+/)
            .some((f) => f === "UpdatedVirtualNum");
          this.setState({ iftrue: ifTrue });

          if (ifTrue) {
            this.setState({
              errorMessage:
                "You will see your local number on rental dashboard once its ready.",
            });
          } else {
            this.setState({ errorMessage: result.split(":")[2].split(".")[0] });
          }
          this.setState({ VRisCancelled: true });
          // if (result === undefined || result === "") {
          //   alert("Something went wrong");
          // } else {
          //   const responseRs = JSON.parse(result);
          //   console.log(responseRs);
          // }
        }
      );
    }
  };

  ERDismiss = () => {
    $(".deleteCardDismissBtn").find("#closebtn2").click();
    window.location.href = `/rental?rc=${this.props.RentalCode.RentalCode}`;
  };

  render() {
    return (
      <>
        {!this.state.VRisCancelled && (
          <>
            <div className="ERfont1">Stay Local</div>
            <div className="ERfont2 p-3">
              Choose a country below to receive a local number your family and
              friends can call you with while your visiting Israel.
            </div>
            <div className="p-2">
              <div className="ERfont3">Choice of country</div>
              <select
                className="form-select statusbtn"
                aria-label="Default select example"
                id="VRselect"
                // value={this.state.Months || ""}
                // onChange={this.handleChangeMonth.bind(this)}
              >
                <option value="0">Please Select</option>
                {this.state.KntCountries.map((kntCountry, i) =>
                  this.props.RentalCode.KNTPlanType == "LT" &&
                  kntCountry.LT_DefaultPlanCode != null ? (
                    <option
                      key={i}
                      value={kntCountry.CountryName}
                      kntlt={kntCountry.LT_DefaultPlanCode}
                      kntst={kntCountry.ST_DefaultPlanCode}
                      kntsfl={kntCountry.SFL_DefaultPlanCode}
                    >
                      {kntCountry.CountryName} - $10
                    </option>
                  ) : this.props.RentalCode.KNTPlanType == "ST" &&
                    kntCountry.ST_DefaultPlanCode != null ? (
                    <option
                      key={i}
                      value={kntCountry.CountryName}
                      kntlt={kntCountry.LT_DefaultPlanCode}
                      kntst={kntCountry.ST_DefaultPlanCode}
                      kntsfl={kntCountry.SFL_DefaultPlanCode}
                    >
                      {kntCountry.CountryName} - $10
                    </option>
                  ) : this.props.KNTPlanType == "SFL" &&
                    kntCountry.SFL_DefaultPlanCode != null ? (
                    <option
                      key={i}
                      value={kntCountry.CountryName}
                      kntlt={kntCountry.LT_DefaultPlanCode}
                      kntst={kntCountry.ST_DefaultPlanCode}
                      kntsfl={kntCountry.SFL_DefaultPlanCode}
                    >
                      {kntCountry.CountryName} - $10
                    </option>
                  ) : (
                    ""
                  )
                )}
              </select>
              <div className="text-danger">{this.state.errors.adData}</div>
            </div>
            {/* <div className="VRContainer m-2">
              <div className="row">
                <div className="col-lg-4 col-4 VRimgBox">
                  <img src="/images/simIcon1.png" />
                </div>
              </div>
              <div className="row">
                <div className="ADFont1 mt-2">{this.props.virNum}</div>
                <div className="ADFont2">Virtual Number</div>
              </div>
            </div> */}
            {/* <div className="ERfont4 pt-3">You’ll be charged</div> */}
            {/* <div className="ERfont4 pt-3">$25.00</div> */}
            <div className="pt-4 pb-3">
              <button className="ERConBtn" onClick={this.ERconfirm2}>
                Update
              </button>
            </div>
          </>
        )}
        {this.state.VRisCancelled && (
          <>
            <div className="ERfont1 ADermargin">
              {this.state.iftrue
                ? "Your Order is in progress!"
                : "Something went worng!"}
            </div>
            <div className="ERfont2 p-3">
              {this.state.iftrue ? (
                "A virtual number will be assigned to your rental within a few minutes. Check your rental dashboard shortly."
              ) : (
                <>
                  <div style={{ color: "red" }}>{this.state.errorMessage}</div>
                </>
              )}
            </div>
            {/* {this.state.iftrue ? (
              <>
                <div
                  className="mt-3 text-center"
                  style={{ color: "green", fontSize: "12px" }}
                >
                  Note: {this.state.errorMessage}
                </div>
              </>
            ) : (
              ""
            )} */}

            {/* <div className="VRContainer m-2">
              <div className="row">
                <div className="col-lg-4 col-4 VRimgBox">
                  <img src="/images/simIcon1.png" />
                </div>
              </div>
              <div className="row">
                <div className="ADFont1 mt-2">{this.props.virNum}</div>
                <div className="ADFont2">Virtual Number</div>
              </div>
            </div> */}
            <div className="pt-1 pb-3">
              <button className="ERConBtn" onClick={this.ERDismiss}>
                Dismiss
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}
export default virtualNumber;
