import React, { useLayoutEffect, useEffect, useState } from "react";
import { ApiGetCall } from "../Functions/connector";

function MultipleEquipment(props) {
  const [inputValues, setInputValues] = useState([]);
  useLayoutEffect(() => {
    var ListData = [];
    for (let i = 0; i < props?.Equipment?.Quantity; i++) {
      ListData.push({
        id: i,
        EquipmentBaseCode: props?.Equipment?.MultipleEquipments
          ? props?.Equipment?.EquipmentCode
          : "",
        AlreadyHaveSim: "",
        Insurance: props?.Equipment?.MultipleEquipments
          ? props?.Equipment?.Insurance
          : "",
        kntCountries: props?.Equipment?.MultipleEquipments
          ? props?.Equipment?.kntCountryCode
          : "",
        smss: props?.Equipment?.MultipleEquipments
          ? props?.Equipment?.smssCode
          : "",
        error: "",
      });
    }
    setInputValues(ListData);
  }, [
    props?.Equipment?.Quantity,
    props?.Equipment?.MultipleEquipments,
    props?.BundleData?.equipments,
  ]);
  useEffect(() => {
    props.setEquipment({
      ...props.Equipment,
      MultipleEquipmentsData: inputValues,
    });
  }, [inputValues]);
  const handleInputChange = async (index, e) => {
    const newInputValues = [...inputValues];

    if (e.target.name == "Insurance") {
      newInputValues[index][e.target.name] = e.target.checked;
    } else {
      newInputValues[index][e.target.name] = e.target.value;
    }

    if (
      e.target.name == "EquipmentBaseCode" &&
      (e.target.value != "9999" ||
        e.target.value != "3070" ||
        e.target.value != "3160" ||
        e.target.value != "3210" ||
        e.target.value != "3300")
    ) {
      newInputValues[index]["AlreadyHaveSim"] = "";
      newInputValues[index]["error"] = "";
      newInputValues[index]["Insurance"] = false;
    }
    let SimValue = e.target.value.replace(/\s/g, "");
    if (
      SimValue.length == 14 &&
      (inputValues[index]["EquipmentBaseCode"] == "9999" ||
        props?.Equipment?.EquipmentCode == "9999")
    ) {
      const isDuplicate = inputValues.some(
        (otherInput) =>
          otherInput.id != index && otherInput.AlreadyHaveSim == e.target.value
      );
      newInputValues[index]["error"] =
        isDuplicate && e.target.value != ""
          ? "SIM number already entered!"
          : "";
      if (!isDuplicate) {
        await ApiGetCall("/api/sim?SIMNumber=89972" + SimValue).then(
          (result) => {
            try {
              if (result == '""') {
                newInputValues[index]["error"] = "Unknown Number!";
              } else {
                newInputValues[index]["error"] = "";
              }
            } catch (error) {
              console.log(error);
            }
          }
        );
      }
    }

    setInputValues(newInputValues);
  };
  const handleMultipleChanges = (index, e) => {
    if (props?.Equipment?.MultipleEquipments) {
      if (e.target.name == "EquipmentBaseCode") {
        var BaseCode =
          e.target.options[e.target.selectedIndex].getAttribute("data-code");
      }

      let newInputValues = [...inputValues];
      for (let i = 0; i < inputValues.length; i++) {
        if (e.target.name == "EquipmentBaseCode") {
          newInputValues[i]["EquipmentBaseCode"] = BaseCode;
          if (BaseCode != "9999") {
            newInputValues[i]["AlreadyHaveSim"] = "";
            newInputValues[i]["error"] = "";
          } else if (
            BaseCode != "3070" ||
            BaseCode != "3160" ||
            BaseCode != "3210" ||
            BaseCode != "3300"
          ) {
            newInputValues[i]["Insurance"] = false;
          }
        } else if (e.target.name == "kntCountries") {
          newInputValues[i]["kntCountries"] = e.target.value;
        } else if (e.target.name == "smss") {
          newInputValues[i]["smss"] = e.target.value;
        } else if (e.target.name == "Insurance") {
          newInputValues[i]["Insurance"] = e.target.checked;
        }

        setInputValues(newInputValues);
      }
      props.setEquipment({
        ...props.Equipment,
        ...(e.target.name == "EquipmentBaseCode" && {
          EquipmentCode: BaseCode,
          ShowShipping: BaseCode == "9999" || BaseCode == "3290" ? false : true,
        }),
        ...(e.target.name == "kntCountries" && {
          kntCountryCode: e.target.value,
        }),
        ...(e.target.name == "smss" && { smssCode: e.target.value }),
        ...(e.target.name == "Insurance" && { smssCode: e.target.checked }),
      });
    } else {
      handleInputChange(index, e);
      if (e.target.name != "kntCountries" || e.target.name == "smss") {
        var AllValuesAreFiled = inputValues.every(
          (obj) => obj.EquipmentBaseCode != ""
        );
        if (AllValuesAreFiled) {
          props.setEquipment({
            ...props.Equipment,
            ShowShipping: inputValues.every(
              (obj) =>
                obj.EquipmentBaseCode == "9999" ||
                obj.EquipmentBaseCode == "3290"
            )
              ? false
              : true,
          });
        }
      }
    }
  };
  return (
    <React.Fragment>
      {props?.Equipment?.MultipleEquipments && (
        <div className="col-12 my-3">
          <div className="form-group">
            <div className="row">
              <div className="col-md-4 col-4 align-self-center">
                <label>
                  Equipment <span className="text-danger">*</span>
                </label>
              </div>
              <div className="col-md-8 col-8">
                <select
                  className="form-select select mb-0"
                  name="EquipmentBaseCode"
                  id="EquipmentData"
                  onChange={(e) => handleMultipleChanges(0, e)}
                >
                  <option value="-1">Please select</option>
                  {props.BundleData?.equipments?.map((data, i) => {
                    return (
                      <>
                        <option
                          key={data?.EquipmentId}
                          value={data?.EquipmentId}
                          data-name={data?.name}
                          data-cost={data?.ECost}
                          data-code={data?.EquipmentCode}
                          data-note={data?.Notes}
                          data-issns={data?.IsSns}
                          data-kosher={data?.kosher}
                          data-issim={data?.IsSim}
                          data-issmartphone={data?.IsSmartPhone}
                        >
                          {data?.name}
                        </option>
                      </>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      )}

      {inputValues.map((element) => (
        <>
          <div className="" key={element?.id}>
            {!props?.Equipment?.MultipleEquipments && (
              <div className="col-12 my-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Equipment
                        {props?.Equipment?.Quantity > 1 && (
                          <> for order #{element.id + 1}</>
                        )}
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <select
                        className="form-select select mb-0"
                        name="EquipmentBaseCode"
                        id=""
                        value={element?.EquipmentBaseCode || ""}
                        onChange={(e) => handleMultipleChanges(element?.id, e)}
                      >
                        <option value="-1">Please select</option>
                        {props.BundleData?.equipments?.map((data) => {
                          return (
                            <>
                              <option
                                key={data?.EquipmentId}
                                value={data?.EquipmentId}
                                data-name={data?.name}
                                data-cost={data?.ECost}
                                data-code={data?.EquipmentCode}
                                data-note={data?.Notes}
                                data-issns={data?.IsSns}
                                data-kosher={data?.kosher}
                                data-issim={data?.IsSim}
                                data-issmartphone={data?.IsSmartPhone}
                              >
                                {data?.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {(element?.EquipmentBaseCode == "9999" ||
              props?.Equipment?.EquipmentCode == "9999") && (
              <div className="col-12 my-3">
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4 col-4 align-self-center">
                      <label>
                        Enter Your 19 Digit SIM card
                        {props?.Equipment?.Quantity > 1 && (
                          <> for order #{element?.id + 1}</>
                        )}
                        <span className="text-danger"> *</span>
                      </label>
                    </div>
                    <div className="col-md-8 col-8">
                      <div className="row">
                        <div className="col-md-3 col-4 pe-0  align-self-center ">
                          <div className="input-group-text">89972</div>
                        </div>
                        <div className="col-md-9 col-8 ps-0">
                          <input
                            className="box w-100"
                            name="AlreadyHaveSim"
                            type="text"
                            placeholder="0000 0000 0000 00"
                            value={element?.AlreadyHaveSim?.replace(
                              /(\d{4})(?=\d)/g,
                              "$1 "
                            )}
                            onChange={(e) => {
                              e.target.value.length <= 17 &&
                                handleInputChange(element?.id, e);
                            }}
                            style={{
                              borderLeft: "none",
                              borderRadius: "0px",
                            }}
                          />
                        </div>
                        {element.error && (
                          <div style={{ color: "red" }}>{element.error}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!props?.Equipment?.MultipleEquipments && (
              <>
                {(element?.EquipmentBaseCode == "3070" ||
                  element?.EquipmentBaseCode == "3160" ||
                  element?.EquipmentBaseCode == "3210" ||
                  element?.EquipmentBaseCode == "3300" ||
                  props?.Equipment?.EquipmentCode == "3070" ||
                  props?.Equipment?.EquipmentCode == "3160" ||
                  props?.Equipment?.EquipmentCode == "3210" ||
                  props?.Equipment?.EquipmentCode == "3300" ||
                  props?.Equipment?.EquipmentCode == "3420") && (
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Insurance
                            {props?.Equipment?.Quantity > 1 && (
                              <> for order #{element?.id + 1}</>
                            )}
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <div className="form-check">
                            <label
                              className="form-check-label ro-font2 c142454"
                              htmlFor="flexCheckDefault"
                            >
                              <span
                                className="my-2"
                                style={{ fontWeight: "700" }}
                              >
                                Yes, Add Insurance at $2.50 + VAT per month
                              </span>
                              <br />
                              <span className="my-2">
                                Don't miss out add insurance today and cover
                                your phone for any damage! * Does not include
                                loss, theft or intentional damage. In case of
                                total loss, you will receive a $35 + VAT credit
                                towards your phone replacement.
                              </span>
                            </label>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                              name="Insurance"
                              defaultChecked={element?.Insurance || ""}
                              onChange={(e) =>
                                handleInputChange(element?.id, e)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {props?.BundleData?.kntCountries?.length > 1 && (
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>
                            Stay Local Number
                            <span className="text-danger"> *</span>
                          </label>
                        </div>
                        <div className="col-md-8 col-8">
                          <select
                            className="form-select select mb-0"
                            name="kntCountries"
                            id=""
                            value={element?.kntCountries || ""}
                            onChange={(e) =>
                              handleMultipleChanges(element?.id, e)
                            }
                            // onChange={(e) => {
                            //   setShowSLNQ(
                            //     e.target.value == "0" || e.target.value == "-1"
                            //       ? false
                            //       : true
                            //   );
                            // }}
                          >
                            <option value="0">Please select</option>
                            {props?.BundleData?.kntCountries?.map((data, i) => {
                              return (
                                <>
                                  <option value={data?.KNTCode}>
                                    {data?.DirectDisplayName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {props?.BundleData?.smss?.length > 1 && (
                  <div className="col-12 my-3">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 col-4 align-self-center">
                          <label>SMS:</label>
                          <label>
                            Your plan includes local (Israel) SMS. Now you can
                            prepay international text messages and save even
                            more!
                          </label>
                        </div>

                        <div className="col-md-8 col-8">
                          <select
                            className="form-select select mb-0"
                            name="smss"
                            id=""
                            value={element?.smss || ""}
                            onChange={(e) =>
                              handleMultipleChanges(element?.id, e)
                            }
                          >
                            <option value="-1">Please select</option>
                            {props?.BundleData?.smss.map((data, i) => {
                              return (
                                <>
                                  <option
                                    key={data?.SmsPackageCode}
                                    value={data?.SmsPackageCode}
                                    data-name={data?.SMSPackageName}
                                  >
                                    {data?.SMSPackageName}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ))}
      {props?.Equipment?.MultipleEquipments && (
        <>
          {(props?.Equipment?.EquipmentCode == "3070" ||
            props?.Equipment?.EquipmentCode == "3160" ||
            props?.Equipment?.EquipmentCode == "3210" ||
            props?.Equipment?.EquipmentCode == "3300" ||
            props?.Equipment?.EquipmentCode == "3420") && (
            <div className="col-12 my-3">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4 col-4 align-self-center">
                    <label>Insurance</label>
                  </div>
                  <div className="col-md-8 col-8">
                    <div className="form-check">
                      <label
                        className="form-check-label ro-font2 c142454"
                        htmlFor="flexCheckDefault"
                      >
                        <span className="my-2" style={{ fontWeight: "700" }}>
                          Yes, Add Insurance at $2.50 + VAT per month
                        </span>
                        <br />
                        <span className="my-2">
                          Don't miss out add insurance today and cover your
                          phone for any damage! * Does not include loss, theft
                          or intentional damage. In case of total loss, you will
                          receive a $35 + VAT credit towards your phone
                          replacement.
                        </span>
                      </label>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        name="Insurance"
                        onChange={(e) => handleMultipleChanges(0, e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {props?.BundleData?.kntCountries?.length > 1 && (
            <div className="col-12 my-3">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4 col-4 align-self-center">
                    <label>
                      Stay Local Number
                      <span className="text-danger"> *</span>
                    </label>
                  </div>
                  <div className="col-md-8 col-8">
                    <select
                      className="form-select select mb-0"
                      name="kntCountries"
                      id=""
                      onChange={(e) => handleMultipleChanges(0, e)}
                    >
                      <option value="0">Please select</option>
                      {props?.BundleData?.kntCountries?.map((data, i) => {
                        return (
                          <>
                            <option value={data?.KNTCode}>
                              {data?.DirectDisplayName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
          {props?.BundleData?.smss?.length > 1 && (
            <div className="col-12 my-3">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4 col-4 align-self-center">
                    <label>SMS:</label>
                    <label>
                      Your plan includes local (Israel) SMS. Now you can prepay
                      international text messages and save even more!
                    </label>
                  </div>

                  <div className="col-md-8 col-8">
                    <select
                      className="form-select select mb-0"
                      name="smss"
                      id=""
                      // value={element?.smss || ""}
                      onChange={(e) => handleMultipleChanges(0, e)}
                    >
                      <option value="-1">Please select</option>
                      {props?.BundleData?.smss.map((data, i) => {
                        return (
                          <>
                            <option
                              key={data?.SmsPackageCode}
                              value={data?.SmsPackageCode}
                              data-name={data?.SMSPackageName}
                            >
                              {data?.SMSPackageName}
                            </option>
                          </>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </React.Fragment>
  );
}

export default MultipleEquipment;
