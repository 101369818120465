import React from "react";
import Loader from "react-loader-spinner";
import MessageContainer from "./MessageContainer";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import FooterLinks from "./footerlinks";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isLoader: false,
    };
  }

  ResetLink = async () => {
    this.setState({ isLoader: true });
    if (
      /^[a-zA-Z0-9.\-_\]+@[a-zA-Z0-9]+\.[A-Za-z.]+$/.test($("#fpEmail").val())
    ) {
      const emailAddress = $("#fpEmail").val();
      var urlencoded = new URLSearchParams();
      urlencoded.append("UserEmail", emailAddress);
      await ApiPostCall("/Auth/forgotpassword", urlencoded).then((result) => {
        if (result == undefined || result == "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          // console.log(responseRs);
          if (responseRs.hasOwnProperty("Error")) {
            const ErrorType = responseRs.Error;
            console.log(responseRs);
            if (ErrorType == "InvalidEmailPassword") {
              $(".generalError")
                .empty()
                .text("Email address not exist. Please check again!");
            } else {
              $(".generalError").empty().text("Invalid data!");
            }
          } else {
            this.setState({
              ValidEmailAddress: emailAddress,
              EmailSent: true,
            });
          }
        }
        this.setState({ isLoader: false });
      });
      this.setState({ isLoader: false });
    } else {
      $("#fpEmail")
        .parent()
        .find(".errorText")
        .text("Enter a valid email address!");
      this.setState({ isLoader: false });
    }
  };

  render() {
    const LinkSentTxt = (
      <div>
        Password sent to {this.state.ValidEmailAddress}
        <br />
        Please check your inbox and follow the instruction on email.
      </div>
    );
    return (
      <>
        {this.state.EmailSent && (
          <MessageContainer
            title={"Request Sent"}
            ButtonText={"Dismiss"}
            content={LinkSentTxt}
            footerText={"Wrong email address!"}
            footerLink={"/forgotpassword"}
            footerLinkText={"Correct It!"}
            IsFooter={"true"}
          />
        )}

        {!this.state.EmailSent && (
          <div className="row m-0 loginPageBody">
            <div className="card col-lg-4 m-auto border-0 ">
              <div className="row p-1">
                <div className="col-12 d-flex justify-content-center align-self-center">
                  <img src="/Tns-Logo.png" />
                </div>
                <div className="col-12 ">
                  <div className="card mt-4 border-0 authCard p-4">
                    <div className="h2 align-self-center themeTxtColor pb-3 pt-3">
                      Forgot Password
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="fpEmail">Email Address</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fpEmail"
                        placeholder="Enter Email"
                      />
                      <p className="errorText"></p>
                    </div>
                    <button
                      type="button"
                      onClick={this.ResetLink}
                      className="btn btn-primary btn-lg btn-block waves-effect waves-light mt-3 themeTxtColor"
                    >
                      Request Password
                      {this.state.isLoader && (
                        <span className="loaderCustom">
                          {" "}
                          <Loader
                            type="Oval"
                            color="#142454"
                            height={25}
                            width={25}
                            timeout={100000}
                          />
                        </span>
                      )}
                    </button>
                    <p className="errorText generalError"></p>
                  </div>
                </div>
              </div>
            </div>
            <FooterLinks
              content={"Remember Password?"}
              link={"/login"}
              linktext={"Login Now!"}
            />
          </div>
        )}
      </>
    );
  }
}

export default ForgotPassword;
