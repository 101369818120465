import React from "react";
import "./rentalcss.css";
import "../rentalPage_popup/rentalPage_popup.css";
import PopupModal from "../Common/Popup";
import AddNewCard from "../Bills/addNewCard";
import ExtendRental from "../rentalPage_popup/extendRental";
import AllDataPopup from "../rentalPage_popup/allDataPopup";

class RentalMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="col-lg-3 col-md-3 col-4 setImagepadding">
          <div className="RimgBox">
            <img src={this.props.img} alt="phone" />
          </div>
        </div>
        <div className="col-lg-9 col-md-9 col-8">
          <div className="row LargeFont1">{this.props.data1}</div>
          <div className="row">
            {this.props.popup == "CCpopup" ? (
              <>
                <div className={this.props.popup}>
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className=""
                    class="LargeFont3"
                    btnName="Edit"
                    size="addNewCardSize deleteCardDismissBtn"
                  >
                    <AddNewCard
                      cnumber={this.props.CardNum}
                      RcoDe={this.props.CardRentalCode}
                      title="Update Payment Method"
                    />
                  </PopupModal>
                </div>
              </>
            ) : this.props.popup == "ExtendRentalpopup" ? (
              <>
                <div className={this.props.popup}>
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className=""
                    class="LargeFont3"
                    btnName="Extend Rental"
                    size="ExtendRentalCardSize deleteCardDismissBtn"
                  >
                    <ExtendRental
                      RcoDe={this.props.CardRentalCode}
                      EDate={this.props.eDate}
                    />
                  </PopupModal>
                </div>
              </>
            ) : this.props.popup == "DataUsagepopup" ? (
              <>
                <div className={this.props.popup}>
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className=""
                    class="LargeFont3"
                    btnName="Add Data"
                    size="ExtendRentalCardSize deleteCardDismissBtn"
                  >
                    <AllDataPopup
                      RcoDe={this.props.CardRentalCode}
                      EDate={this.props.eDate}
                    />
                  </PopupModal>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="LargeFont2 fr">{this.props.data2}</div>
        </div>
        <div className={`row ${this.props.DisplayNone}`}>
          <div className="progress">
            <div
              className="progress-bar pb "
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${this.props.progressData}%` }}
            ></div>
          </div>
        </div>
        {/* <div className="row pt-3">
            <div className="col-4">
              <div className="LargeFont3">{this.props.data3}</div>
            </div>
            <div className="col-8">
              <div className="progress">
                <div
                  className="progress-bar w-75 pb "
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </>
    );
  }
}

export default RentalMobile;
