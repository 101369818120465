import React from "react";
import "../Oraganism/DataTable.css";
import "./invoice&usageCss.css";
import "./rentalcss.css";
import "./ChnagePlan.css";
import RentalL from "./rentalLargeCom";
import RentalM from "./rentalMobile";
import DataTableUsage from "../Oraganism/DataTableUsage";
import { ApiPostCall } from "../Functions/connector";
import PopupModal from "./Popup";
import ChangePlan from "./changePlan";
import $ from "jquery";

class Usage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFilter: "filterContainerMobile",
      rentals: [],
      popupSize: "usageSize",
      isCancelled: false,
      tblUsage: [],
      tblBills: [],
      tblDirect: [],
      tblLinkRentalCallPackage: [],
      tblLinkRentalExtendedDataPackage: [],
      tblLinkRentalMonthlyFees: [],
      tblLinkRentalOnceFees: [],
      tblLinkRentalSMSPackage: [],
      tblOrders: [],
      tblRentals: [],
      tblUsage_Data: [],
      getrentalC: "0",
      Months: "0",
      ifNumisZero: "",
    };
  }

  confirm = () => {
    this.setState({ popupSize: "changePlanDSize" });
    this.setState({ isCancelled: true });
  };

  Dismiss = () => {
    $(".changePlanDismissBtn").find("#closebtn2").click();
    setTimeout(() => {
      this.setState({ popupSize: "usageSize" });
      this.setState({ isCancelled: false });
    }, 200);
  };

  componentDidMount() {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      this.setState({ rentals: foundUser[0] });
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let rcode = params.rc;
    let Month = params.mn;
    this.setState({ Months: Month });
    this.setState({ getrentalC: rcode });
    if (rcode == 0) {
      rcode = 0;
      this.setState({ ifNumisZero: rcode });
    } else {
      this.setState({ ifNumisZero: "0" });
    }
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("RentalCode", rcode);
    // urlencoded.append("RentalCode", 383857);
    urlencoded.append("Token", token);
    ApiPostCall("/RouterParser/rental ", urlencoded).then((result) => {
      // console.log("got it");
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        const responseRs = JSON.parse(result);
        console.log(responseRs);
        // var newState = {};
        // var data = {};
        // var arr2 = [
        //   responseRs.tblUsage,
        //   responseRs.tblBills,
        //   responseRs.tblDirect,
        //   responseRs.tblLinkRentalCallPackage,
        //   responseRs.tblLinkRentalExtendedDataPackage,
        //   responseRs.tblLinkRentalMonthlyFees,
        //   responseRs.tblLinkRentalOnceFees,
        //   responseRs.tblLinkRentalSMSPackage,
        //   responseRs.tblOrders,
        //   responseRs.tblRentals,
        // ];
        // for (var j = 0; j < arr2.length; i++) {
        //   data = JSON.parse(arr2[j]);
        //   console.log(data);
        //   // newState.push(data[0][0]);
        //   // console.log(newState);
        //   // this.setState(newState);
        //   // console.log(newState);
        // }
        const tblBills = JSON.parse(responseRs.tblBills);
        this.setState({ tblBills: tblBills[0][0] });
        console.log(this.state.tblBills);
        //
        const tblDirect = JSON.parse(responseRs.tblDirect);
        this.setState({ tblDirect: tblDirect[0][0] });
        console.log(this.state.tblDirect);
        //
        const tblLinkRentalCallPackage = JSON.parse(
          responseRs.tblLinkRentalCallPackage
        );
        this.setState({
          tblLinkRentalCallPackage: tblLinkRentalCallPackage[0][0],
        });
        console.log(this.state.tblLinkRentalCallPackage);
        //
        const tblLinkRentalExtendedDataPackage = JSON.parse(
          responseRs.tblLinkRentalExtendedDataPackage
        );
        this.setState({
          tblLinkRentalExtendedDataPackage:
            tblLinkRentalExtendedDataPackage[0][0],
        });
        console.log(this.state.tblLinkRentalExtendedDataPackage);
        //
        const tblLinkRentalMonthlyFees = JSON.parse(
          responseRs.tblLinkRentalMonthlyFees
        );
        this.setState({
          tblLinkRentalMonthlyFees: tblLinkRentalMonthlyFees[0][0],
        });
        console.log(this.state.tblLinkRentalMonthlyFees);
        //
        const tblLinkRentalOnceFees = JSON.parse(
          responseRs.tblLinkRentalOnceFees
        );
        this.setState({ tblLinkRentalOnceFees: tblLinkRentalOnceFees[0] });
        console.log(this.state.tblLinkRentalOnceFees);
        //
        const tblLinkRentalSMSPackage = JSON.parse(
          responseRs.tblLinkRentalSMSPackage
        );
        this.setState({
          tblLinkRentalSMSPackage: tblLinkRentalSMSPackage[0][0],
        });
        console.log(this.state.tblLinkRentalSMSPackage);
        //
        const tblOrders = JSON.parse(responseRs.tblOrders);
        this.setState({ tblOrders: tblOrders[0][0] });
        console.log(this.state.tblOrders);
        //
        const tblRentals = JSON.parse(responseRs.tblRentals);
        this.setState({ tblRentals: tblRentals[0][0] });
        console.log(this.state.tblRentals);

        const tblUsage = JSON.parse(responseRs.tblUsage);
        this.setState({ tblUsage: tblUsage[0] });
        console.log(this.state.tblUsage);

        const tblUsage_Data = JSON.parse(responseRs.tblUsage_Data);
        this.setState({ tblUsage_Data: tblUsage_Data[0] });
        console.log(this.state.tblUsage_Data);
      }
    });
  }

  handleChangePhoneNo(e) {
    this.setState({ getrentalC: e.target.value });
  }

  handleChangeMonth(e) {
    this.setState({ Months: e.target.value });
  }
  applyFilter = () => {
    var PhoneNo = $("#phoneSelect").find(":selected");
    var rentalNumber = $(PhoneNo).attr("value");
    var month = $("#select").find(":selected");
    var month = $(month).attr("value");
    window.location.href = "/usage?&rc=" + rentalNumber + "&mn=" + month;
  };
  filterSwap = () => {
    this.state.mobileFilter == ""
      ? this.setState({ mobileFilter: "filterContainerMobile" })
      : this.setState({ mobileFilter: "" });
  };

  render() {
    let USLocale = Intl.NumberFormat("en-US");
    if (this.state.tblUsage_Data) {
      var usages_Data = this.state.tblUsage_Data;
      var TotalDailyUsageData = 0;
      for (var i = 0; i < usages_Data.length; i++) {
        TotalDailyUsageData += usages_Data[i].DailyUsageGB;
      }
    }
    if (this.state.tblUsage) {
      var usagesData = this.state.tblUsage;
      var min = this.state.tblLinkRentalCallPackage;
      var TotalDailyUsageSms = 0;
      var TotalDailyUsageLocalMinutes = 0;
      var TotalDailyUsageIntlMinutes = 0;
      var TotalMonthsLocalMin = 0;
      var TotalMonthsLocalIntl = 0;
      var totallocalmin = 0;
      var TotalMin = 0;
      for (var j = 0; j < usagesData.length; j++) {
        TotalDailyUsageSms += usagesData[j].SMS;
        TotalDailyUsageLocalMinutes += usagesData[j].DomesticRunningMinutes;
        TotalDailyUsageIntlMinutes += usagesData[j].IntlRunningMinutes;
        TotalMin += min.Minutes;
        if (this.state.Months != 0) {
          if (this.state.tblUsage[j].Month == this.state.Months) {
            TotalMonthsLocalMin += usagesData[j].DomesticRunningMinutes;
            TotalMonthsLocalIntl += usagesData[j].IntlRunningMinutes;
            totallocalmin += usagesData[j].PackageSize;
          }
        }
      }
    }
    if (this.state.tblLinkRentalSMSPackage) {
      var localSmsUsageBar =
        (TotalDailyUsageSms /
          this.state.tblLinkRentalSMSPackage.CCPSMSBalance) *
        100;
      if (
        this.state.tblLinkRentalSMSPackage.CCPSMSBalance < TotalDailyUsageSms
      ) {
        var localSmsUsageBar = 100;
      }
    }
    if (this.state.Months != 0) {
      var localMinUsageBar = (TotalMonthsLocalMin / totallocalmin) * 100;
      if (totallocalmin < TotalMonthsLocalMin) {
        var localMinUsageBar = 100;
      }
    } else if (this.state.tblLinkRentalCallPackage) {
      var localMinUsageBar = (TotalDailyUsageLocalMinutes / TotalMin) * 100;
      if (TotalMin < TotalDailyUsageLocalMinutes) {
        var localMinUsageBar = 100;
      }
    }

    return (
      <>
        <div className="invoicePageBody">
          <div className="row">
            <div className="col-12">
              <div
                className={`mobile topHeader mMargin ${
                  this.state.mobileFilter == "" ? "d-none" : ""
                } `}
              >
                Usage
                <i
                  className="fa fa-solid fa-filter pullRight mr pt-2"
                  onClick={this.filterSwap}
                ></i>
                {/* <div className="changebtnMmargin">
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className="btn btn changeplanMbtn mobile"
                    btnName="Change Plan"
                    size={` ${this.state.popupSize} changePlanDismissBtn`}
                  >
                    <ChangePlan
                      confirmClick={this.confirm}
                      popupSize={this.state.isCancelled}
                      dismissClick={this.Dismiss}
                    />
                  </PopupModal>
                </div> */}
              </div>
            </div>
            {/* <form onSubmit={this.handleSubmit}> */}
            <div className=" col-lg-12 col-md-12 col-12">
              <div
                className={`row filterContainerUsage  ${this.state.mobileFilter} `}
              >
                <div className="col-10 mobile">
                  <div className="filterInvoice mobile">Filter Usage</div>
                </div>
                <div
                  className="col-2 closebtn mobile"
                  onClick={this.filterSwap}
                >
                  x
                </div>
                <div className="col-lg-3 col-md-3 col-12 contanierWidthUsage ">
                  <div className="phoneData">Phone Number</div>
                  <div className="">
                    <select
                      className="form-select phonebtn"
                      aria-label="Default select example"
                      id="phoneSelect"
                      style={{ paddingRight: "0px" }}
                      value={this.state.getrentalC || ""}
                      onChange={this.handleChangePhoneNo.bind(this)}
                    >
                      <option value="0">Choose Phone No.</option>
                      {this.state.rentals.map((rental1, i) => (
                        <option key={i} value={rental1.RentalCode}>
                          {rental1.PhoneNumber}
                        </option>
                      ))}
                    </select>
                    <div className="text-danger">
                      {/* {this.state.errors.pNum} */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-3 col-12 contanierWidthUsage ">
                  <div className="statusData">Month</div>
                  <div className="">
                    <select
                      className="form-select statusbtn"
                      aria-label="Default select example"
                      id="select"
                      value={this.state.Months || ""}
                      onChange={this.handleChangeMonth.bind(this)}
                    >
                      <option value="0">Select Month</option>
                      <option value="1">Janaury</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <div className="text-danger">
                      {/* {this.state.errors.status} */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-3 col-12 contanierWidthUsage ">
                  <button
                    type="submit"
                    value="Submit"
                    className="btn btn applybtnUsage"
                    onClick={this.applyFilter}
                  >
                    <div className="applyFont">Apply Filters</div>
                  </button>
                </div>
                {/* <div className="col-lg-2 col-md-2 col-12 desktop ">
                  <PopupModal
                    type="submit"
                    value="Submit"
                    className="btn btn changePlan"
                    btnName="Change Plan"
                    size={` ${this.state.popupSize} changePlanDismissBtn`}
                  >
                    <ChangePlan
                      confirmClick={this.confirm}
                      popupSize={this.state.isCancelled}
                      dismissClick={this.Dismiss}
                    />
                  </PopupModal>
                </div> */}
              </div>
            </div>
            {/* </form> */}
          </div>
          <div className="row ">
            <div className="col-lg-6 col-md-6 pt-3 desktop UsagepaddingL">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer1">
                <div className="row LargeUsageStyle1 alignitemcenter">
                  <RentalL
                    data1="Local Minutes"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : this.state.Months != 0 &&
                          this.state.Months != null &&
                          this.state.Months != undefined
                        ? `${USLocale.format(
                            TotalMonthsLocalMin
                          )}min/${USLocale.format(totallocalmin)}min`
                        : (this.state.tblUsage_Data &&
                            this.state.tblLinkRentalCallPackage) == null
                        ? "NA"
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageLocalMinutes)
                          }min/${
                            this.state.tblLinkRentalCallPackage == null &&
                            this.state.tblLinkRentalCallPackage == ""
                              ? "NA"
                              : USLocale.format(TotalMin)
                          }min`
                    }
                    popup="d-none"
                    data3=""
                    img="/images/BigTele.png"
                    progressData={localMinUsageBar}
                  />
                </div>
                {/* <div className="row LargeUsageStyle1 pt-4 alignitemcenter">
                  <RentalL
                    data1="Local Text"
                    data2="150/200"
                    data3="All Texts"
                    img="/images/bigMessageGreen.png"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mPadding  mobile">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer1">
                <div className="row LargeUsageStyle1 alignitemcenter">
                  <RentalM
                    data1="Local Minutes"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : this.state.Months != 0 &&
                          this.state.Months != null &&
                          this.state.Months != undefined
                        ? `${USLocale.format(
                            TotalMonthsLocalMin
                          )}min/${USLocale.format(totallocalmin)}min`
                        : (this.state.tblUsage_Data &&
                            this.state.tblLinkRentalCallPackage) == null
                        ? "NA"
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageLocalMinutes)
                          }min/${
                            this.state.tblLinkRentalCallPackage == null &&
                            this.state.tblLinkRentalCallPackage == ""
                              ? "NA"
                              : USLocale.format(TotalMin)
                          }min`
                    }
                    popup="d-none"
                    data3=""
                    img="/images/BigTele.png"
                    progressData={localMinUsageBar}
                  />
                </div>
                {/* <div className="row LargeUsageStyle1 pt-4 alignitemcenter">
                  <RentalM
                    data1="Local Text"
                    data2="150/200"
                    data3="All Texts"
                    img="/images/bigMessageGreen.png"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 pt-3 desktop UsagepaddingR">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer2">
                <div className="row LargeUsageStyle2 alignitemcenter">
                  <RentalL
                    data1="International Minutes"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : this.state.Months != 0 &&
                          this.state.Months != null &&
                          this.state.Months != undefined
                        ? `${USLocale.format(TotalMonthsLocalIntl)}min`
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageIntlMinutes)
                          }min`
                    }
                    popup="d-none"
                    data3=""
                    img="/images/bigPhoneLG.png"
                    progressData="100"
                    DisplayNone="d-none"
                  />
                </div>
                {/* <div className="row LargeUsageStyle2 pt-4 alignitemcenter">
                  <RentalL
                    data1="International Text"
                    data2="150/200"
                    data3="All Texts"
                    img="/images/bigMessageLG.png"
                  />
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mPadding  mobile">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer2">
                <div className="row LargeUsageStyle2 alignitemcenter">
                  <RentalM
                    data1="International Minutes"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : this.state.Months != 0 &&
                          this.state.Months != null &&
                          this.state.Months != undefined
                        ? `${USLocale.format(TotalMonthsLocalIntl)}min`
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageIntlMinutes)
                          }min`
                    }
                    popup="d-none"
                    data3=""
                    progressData="100"
                    img="/images/bigPhoneLG.png"
                    DisplayNone="d-none"
                  />
                </div>
                {/* <div className="row LargeUsageStyle2 pt-4 alignitemcenter">
                  <RentalM
                    data1="International Text"
                    data2="150/200"
                    data3="All Texts"
                    img="/images/bigMessageLG.png"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 desktop UsagepaddingL">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer3">
                <div className="row LargeUsageStyle3  alignitemcenter">
                  <RentalL
                    data1="Data Usage"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageData)
                          }`
                    }
                    // data3="Add Data"
                    popup="d-none"
                    progressData="100"
                    img="/images/bigDataBlue.png"
                    DisplayNone="d-none"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6  mPadding mobile">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer3">
                <div className="row LargeUsageStyle3  alignitemcenter">
                  <RentalM
                    data1="Data Usage"
                    data2={
                      this.state.ifNumisZero === 0
                        ? "0"
                        : `${
                            this.state.tblUsage_Data == null
                              ? "NA"
                              : USLocale.format(TotalDailyUsageData)
                          }`
                    }
                    // data3="Add Data"
                    popup="d-none"
                    progressData="100"
                    img="/images/bigDataBlue.png"
                    DisplayNone="d-none"
                  />
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 col-md-6 desktop UsagepaddingR">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer4">
                <div className="row LargeUsageStyle4  alignitemcenter">
                  <RentalL
                    data1="Roaming"
                    data2="10.5GB/20GB"
                    data3="Add Data"
                    img="/images/BigDataRed.png"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mPadding mobile">
              <div className="col-lg-12 col-md-12 p-4 lm  UdataContainer4">
                <div className="row LargeUsageStyle4  alignitemcenter">
                  <RentalM
                    data1="Roaming"
                    data2="10.5GB/20GB"
                    data3="Add Data"
                    img="/images/BigDataRed.png"
                  />
                </div>
              </div>
            </div> */}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="col-lg-12 col-md-12">
                <div className="DataTableUsageMargin">
                  <h5>Addons</h5>
                  <DataTableUsage RNum={this.state.tblLinkRentalOnceFees} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
              <h5>Rental Status</h5>
              <div className="col-lg-5 col-md-5 p-3 activeStatus">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-2 setImagepadding">
                    <div className="ActiveBox">
                      {this.state.ifNumisZero === 0 ? (
                        ""
                      ) : this.state.tblLinkRentalCallPackage.Active == true ? (
                        <img src="/images/ActiveLogo.png" alt="ActiveLogo" />
                      ) : (
                        <img
                          src="/images/InActiveLogo.png"
                          alt="InActiveLogo"
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-2 col-2 Rstatus">
                    <h5>
                      {this.state.ifNumisZero === 0
                        ? ""
                        : this.state.tblLinkRentalCallPackage.Active == true
                        ? "Active"
                        : "Inactive"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Usage;
