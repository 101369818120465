import React from "react";
import "./carousel.css";

class RentalCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rental } = this.props;
    let monthlyFee = 0;
    if (
      rental.MonthlyFees != null &&
      rental.MonthlyFees != undefined &&
      rental.MonthlyFees > 0
    ) {
      monthlyFee = rental.MonthlyFees;
    }
    let headerImage = "/images/iphoneIcon.png";
    if (
      rental.EquipmentModel != null &&
      rental.EquipmentModel != undefined &&
      rental.EquipmentModel.includes("SIM")
    ) {
      headerImage = "/images/simIcon.png";
    }
    var EDateClassNone = "";
    var sDate = rental.StartDateS.split("/");
    var eDate = rental.EndDateS.split("/");
    var sd = parseInt(sDate[2]) + parseInt(2);
    if (sd <= eDate[2]) {
      EDateClassNone = "d-none";
    }
    return (
      <div className={`${this.props.statusClass}  cardContainer `}>
        <div className="col-lg-2 dataContainer">
          <div className="imgBox">
            <img src={`${headerImage}`} />
          </div>
        </div>
        <div className="dataContainer row pno">
          <div className="sliderNum">{rental.PhoneNumberStr}</div>
          <div className="sliderVal">{rental.EquipmentModel}</div>
        </div>

        <div className="dataContainer col-lg-12 col-md-12 col-12 ">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 Sactive ">
              <div className="sliderH2">Status</div>
              <div className="sliderH3">
                {rental.Status == "Returned / Completed"
                  ? "Inactive"
                  : "" || rental.Status == "Expired (awaiting return)"
                    ? "Contact Customer Service"
                    : "" || rental.Status == "Active"
                      ? "Active"
                      : ""}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-6 ">
              <div className="sliderH2">Start Date</div>
              <div className="sliderH3">{rental.StartDateS}</div>
            </div>
            {!rental.IsKosher && !rental.IsStudent && (
              <>
                <div className={`col-lg-3 col-md-3 col-6 ${EDateClassNone}`}>
                  <div className="sliderH2">End Date</div>
                  <div className="sliderH3">{rental.EndDateS}</div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="dataContainer col-lg-12 col-md-12 col-12 Efee">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="sliderH2">Equipment</div>
              <div className="sliderH3">{rental.EquipmentModel}</div>
            </div>

            {/* <div className="col-lg-4 col-md-4 col-6 ">
              <div className="sliderH2">Fee</div>
              <div className="sliderH3">{`$${monthlyFee}/month`} </div>
            </div> */}
          </div>
        </div>
        <div className="dataContainer col-lg-12 col-md-12 col-12 row button">
          {rental.Status == "Active" ? (
            <div className="col-lg-4 col-md-4 col-6 button2">
              <a href={`/rental?rc=${rental.RentalCode}`}>
                <button className="btn btn-secondary w-100  " id="rnum">
                  View Details
                </button>
              </a>
            </div>
          ) : (
            ""
          )}
          {rental.BalanceDue > 0 && <div className="col-lg-4 col-md-4 col-6 button2">
            <a href={`/invoice?rc=${rental.RentalCode}`}>
              <button className="btn btn-secondary w-100  " id="rnum">
                View Bills
              </button>
            </a>
          </div>}

        </div>
      </div>
    );
  }
}

export default RentalCard;
