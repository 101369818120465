import React, { useState, useLayoutEffect } from "react";
import "./payment.css";
import $ from "jquery";
import Loader from "react-loader-spinner";
import { ApiPostCall } from "../Functions/connector";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAfterTenYears } from "../Helper/HelperFun";
import { AddNewCardValidation } from "../Yup Validation/YupValidations";

export default function AddNewCard(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddNewCardValidation),
  });
  const [loaderStatus, setLoaderStatus] = useState({
    isCancelled: false,
    isLoader: false,
  });
  const [rental, setRental] = useState([]);

  useLayoutEffect(() => {
    const loggedInUser = window.localStorage.getItem("userData");
    if (loggedInUser) {
      setRental(JSON.parse(loggedInUser)[0]);
    }
    $("#credit-card").on("keypress change autocompleteselect", function () {
      $(this).val(function (index, value) {
        return value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
      });
    });
  }, []);

  const SubmitData = (data) => {
    let RCode = "";
    let isValid = true;
    if (props.title == "Add New Card") {
      let rCode = $("#phoneNo").find(":selected");
      RCode = $(rCode).attr("value");
      if (RCode == undefined || RCode == null) {
        $(".addNewCardPhoneNum").text("");
        $(".addNewCardPhoneNum").text("Please select phone number.");
        isValid = false;
      } else {
        $(".addNewCardPhoneNum").text("");
      }
    } else {
      RCode = props.RcoDe;
    }
    if (isValid) {
      const token = window.localStorage.getItem("Token");
      var urlencoded = new URLSearchParams();
      urlencoded.append("rentalCode", RCode);
      urlencoded.append("cardType", data.cardType);
      urlencoded.append("ccExpireYear", data.cardYear);
      urlencoded.append("ccExpireMonth", data.cardMonth);
      urlencoded.append("ccNum", data.cardNum);
      urlencoded.append("Token", token);
      setLoaderStatus({ ...loaderStatus, isLoader: true });

      ApiPostCall("/RouterParser/ChangeCCNum ", urlencoded).then((result) => {
        if (result == undefined || result == "") {
          $(".IncorrectCardNumber").text("");
          $(".IncorrectCardNumber").text("Please enter Valid Card number.");
          setLoaderStatus({ ...loaderStatus, isLoader: false });
        } else {
          $(".IncorrectCardNumber").text("");
          setLoaderStatus({
            ...loaderStatus,
            isLoader: false,
            isCancelled: true,
          });
        }
      });
    }
  };

  return (
    <>
      {!loaderStatus.isCancelled && (
        <div>
          <form onSubmit={handleSubmit(SubmitData)}>
            <div className="row p-1 DCfont1 justify-content-center">
              {props.title}
            </div>
            <div className="row">
              <div className="col-lg ">
                {props.title == "Add New Card" ? (
                  <>
                    <div className="Font">Phone Number</div>
                    <select
                      className="form-select selectbtn Mwidth"
                      aria-label="Default select example"
                      id="phoneNo"
                    >
                      <option className="">Please Select</option>
                      {rental.map((rental, i) => (
                        <>
                          <option key={i} value={rental.RentalCode}>
                            {rental.PhoneNumberStr}
                          </option>
                        </>
                      ))}
                    </select>
                    <span className="text-danger addNewCardPhoneNum"></span>
                  </>
                ) : (
                  <div className="Font FontSizeUpdate">{`Update card details for Phone # :${
                    props.cnumber == undefined ? "NA" : props.cnumber
                  }`}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg ">
                <div className="Font">Card Type</div>
                <select
                  className="form-select selectbtn Mwidth"
                  aria-label="Default select example"
                  id="CardType"
                  {...register("cardType")}
                >
                  <option defaultValue className="PlaceHolder" value="">
                    Please Select
                  </option>
                  <option value="Discover">Discover</option>
                  <option value="MasterCard">MasterCard</option>
                  <option value="Visa">Visa</option>
                </select>
                <span className="text-danger">{errors.cardType?.message}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg ">
                <div className="Font">Card Number</div>
                <input
                  type="text"
                  name="name"
                  className="form-control selectbtn Mwidth"
                  placeholder="XXXX - XXXX - XXXX - XXXX"
                  id="credit-card"
                  maxLength="19"
                  {...register("cardNum")}
                />
                <span className="text-danger">
                  {errors.cardNum?.message
                    .split(/[\s,\?\,\.!]+/)
                    .some((f) => f === "`number`")
                    ? "Please enter only digits."
                    : errors.cardNum?.message}
                </span>
                <span className="text-danger IncorrectCardNumber"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-lg ">
                <div className="Font">Expiration Date</div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-6">
                    <select
                      className="form-select selectbtn NWidht"
                      aria-label="Default select example"
                      id="month"
                      {...register("cardMonth")}
                    >
                      <option defaultValue className="PlaceHolder" value="">
                        Month
                      </option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                    <span className="text-danger">
                      {errors.cardMonth?.message}
                    </span>
                  </div>

                  <div className="col-lg-6 col-md-6 col-6">
                    <select
                      className="form-select selectbtn NWidht"
                      aria-label="Default select example"
                      id="year"
                      {...register("cardYear")}
                    >
                      <option defaultValue className="PlaceHolder" value="">
                        Year
                      </option>
                      {getAfterTenYears().map((yr, i) => (
                        <>
                          <option key={i} value={yr}>
                            {yr}
                          </option>
                        </>
                      ))}
                    </select>
                    <span className="text-danger">
                      {errors.cardYear?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-3">
              <div className="col-lg-6 col-md-6 col-6 ">
                <button
                  className="dismissOSbtn dismissANCbtn"
                  onClick={() => {
                    $(".deleteCardDismissBtn").find("#closebtn2").click();
                  }}
                >
                  Dismiss
                </button>
              </div>

              <div className="col-lg-6 col-md-6 col-6 ">
                <button
                  type="submit"
                  value="Submit"
                  className="confirmbtn SaveANCBtn"
                >
                  Save
                  {loaderStatus.isLoader && (
                    <span className="loaderCustom">
                      <Loader
                        type="Oval"
                        color="#142454"
                        height={25}
                        width={25}
                        timeout={100000}
                      />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
      {loaderStatus.isCancelled && (
        <>
          <div className="message">
            Your request to update your account has been received. You will
            receive a confirmation when the change has been made.
          </div>
          <div className="row mt-4 ">
            <div className="col-lg-12 col-md-12 col-12 BTNCenter">
              <button
                className="dismissOSbtn dismissANCbtn Dismis"
                onClick={() => {
                  $(".deleteCardDismissBtn").find("#closebtn2").click();
                }}
              >
                Dismiss
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
