import React, { useState, useEffect } from "react";
import "./orderStatus.css";
// import PopupModal from "../Common/Popup";
// import CancelOrderStatus from "./cancelOrderStatus";
import $ from "jquery";
import { ApiPostCall } from "../Functions/connector";
import OrderStatusContainer from "./orderStatusContainer";
const { convertUnixToDateTime } = require("../Helper/HelperFun");

export default function OrderStatus(props) {
  const [WebServiveDatas, setWebServiveDatas] = useState([]);
  const [CallSmsDatas, setCallSmsDatas] = useState([]);
  const [tblOrderOnline, settblOrderOnline] = useState([]);
  const [dataStatus, setdataStatus] = useState({ loader: true, status: false });

  const orderData = props.orderStatusData;

  useEffect(() => {
    const token = window.localStorage.getItem("Token");
    var urlencoded = new URLSearchParams();
    urlencoded.append("Token", token);
    urlencoded.append("OrderCode", orderData.OrderCode);
    //urlencoded.append("OrderCode", 361963);
    ApiPostCall("/RouterParser/OrderByCode", urlencoded).then((result) => {
      var ifTrue = result.split(/[\s,\?\,\.!]+/).some((f) => f === "Cancelled");
      if (ifTrue) {
        setdataStatus({ ...dataStatus, loader: false, status: true });
      } else {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          let data = JSON.parse(result);
          let WebServiveData = [];
          let CallSmsData = [];
          settblOrderOnline(JSON.parse(data[2])[0]);
          for (let i = 0; i < data[0].length; i++) {
            WebServiveData.push(JSON.parse(data[0][i])[0][0]);
            CallSmsData.push(JSON.parse(data[1][i])[0][0]);
          }
          console.log(WebServiveData);
          console.log(JSON.parse(data[2])[0]);
          console.log(CallSmsData);
          setWebServiveDatas(WebServiveData);
          setCallSmsDatas(CallSmsData);
          setdataStatus({ ...dataStatus, loader: false, status: false });
        }
      }
    });
    console.log(tblOrderOnline);
  }, []);
  return (
    <>
      {dataStatus.loader ? (
        <>
          <span className="d-none isGotData">0</span>
          <div className="loader_positon text-center">
            <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
            <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
              Loading...
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mt-3 ms-1">
            <div className="col-1">
              <img
                src="./images/backArrow.svg"
                alt=""
                className="cursorPointerThead"
                onClick={() => {
                  // props.setOrderStatus(false);
                  window.location.href = "/orders?rc=0";
                  $(".isGotData").text("1");
                }}
              />
            </div>
          </div>
          {dataStatus.status ? (
            <>
              <div className="row">
                <div className="col-lg-12 orderStatusContainer mb-5">
                  <div className="productTitleContainer p-3">
                    <div className="row">
                      <div className="col-lg-3 col-6 MobileMargin">
                        <div className="OSfont3">Order ID</div>
                        <div className="OSfont4">
                          {orderData.OnlineOrderCode}
                        </div>
                      </div>
                      <div className="col-lg-2 col-6 MobileMargin">
                        <div className="OSfont3">Status</div>
                        <div className="OSfont4">Order Cancelled!</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-lg-12 orderStatusContainer mb-5">
                  <div className="productTitleContainer p-3">
                    <div className="row">
                      <div className="col-lg-3 col-12">
                        <div className="OSfont1">
                          {orderData.EquipmentModel}
                        </div>
                        <div className="OSfont2">On Transit</div>
                      </div>
                      <div className="col-lg-3 col-6 MobileMargin">
                        <div className="OSfont3">Order ID</div>
                        <div className="OSfont4">
                          {tblOrderOnline[0]?.OnlineOrderCode}
                          {tblOrderOnline?.length > 1 &&
                            `-${
                              tblOrderOnline[tblOrderOnline?.length - 1]
                                ?.OnlineOrderCode
                            }`}
                        </div>
                      </div>
                      {tblOrderOnline[0]?.ShipMethod != "ALREADY_HAVE_SIM" && (
                        <>
                          <div className="col-lg-2 col-6 MobileMargin">
                            <div className="OSfont3">
                              {orderData.ShipMethod == "OFFICE_PICKUP"
                                ? "Office Pickup"
                                : "Ship Date"}
                            </div>
                            <div className="OSfont4">
                              {
                                convertUnixToDateTime(orderData.ShipDate).split(
                                  ","
                                )[0]
                              }
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* New Changes */}
                  <div className="m-4">
                    <div className="row pb-3">
                      <div className="col-lg-12 col-12 ODFont1 c142454 ">
                        Contact Information
                      </div>
                    </div>
                    <div className="OD_Container p-4">
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Name
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {orderData.UserName}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                          Email
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {orderData.ClientEmail}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                          Phone
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {orderData.ClientMobile}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                          Service Date
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 ">
                          {WebServiveDatas?.map((items, i) => {
                            return (
                              <>
                                <div className="my-2" key={i}>
                                  <div className="row  ODFont3 justify-content-end text-end">
                                    First date of rental:{" "}
                                    {
                                      convertUnixToDateTime(
                                        items.StartDate
                                      ).split(",")[0]
                                    }
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="row py-3">
                      <div className="col-lg-12 col-12 ODFont1 c142454 ">
                        Payment Info
                      </div>
                    </div>
                    <div className="OD_Container p-4">
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Type of Card
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {WebServiveDatas[0]?.CCTitle}{" "}
                          {`XXXXXXXXXXXX-${WebServiveDatas[0]?.CCNum}`}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Setup Fee
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          ${WebServiveDatas[0]?.OneTimeFees}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Billing in
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {WebServiveDatas[0]?.CurrencyCode}
                        </div>
                      </div>
                      {/* <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Payment Status
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          Test
                        </div>
                      </div> */}
                    </div>

                    <div className="row py-3">
                      <div className="col-lg-12 col-12 ODFont1 c142454 ">
                        Shipping Info
                      </div>
                    </div>
                    <div className="OD_Container p-4">
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Type
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {tblOrderOnline[0]?.ShipMethod=="OFFICE_PICKUP" ? "Pickup" : tblOrderOnline[0]?.ShipMethod}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Amount
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          ${tblOrderOnline[0]?.ShipFee}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Address
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {tblOrderOnline[0]?.UserName}
                          <br />
                          {tblOrderOnline[0]?.ShipStreet}{" "}
                          {tblOrderOnline[0]?.ShipCity}
                          <br />
                          {tblOrderOnline[0]?.ShipState}{" "}
                          {tblOrderOnline[0]?.ShipCountry}
                          <br />
                          {tblOrderOnline[0]?.ShipPostalCode}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Phone
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {tblOrderOnline[0]?.ShipPhone}
                        </div>
                      </div>
                      <hr />
                      <div className="row ">
                        <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454 ">
                          Departure date
                        </div>
                        <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                          {
                            new Date(
                              parseInt(
                                tblOrderOnline[0]?.DepartureDate.replace(
                                  "/Date(",
                                  ""
                                ).replace(")/", "")
                              )
                            )
                              .toLocaleString("en-US", {
                                hour12: true,
                              })
                              .split(",")[0]
                          }
                        </div>
                      </div>
                    </div>
                    <hr className="my-5" />

                    <div className="row">
                      {WebServiveDatas?.map((items, i) => {
                        return (
                          <>
                            <OrderStatusContainer
                              OsData={items}
                              callData={CallSmsDatas[i]}
                              tblOnlineData={tblOrderOnline[i]}
                            />
                            {i % 2 === 1 && <hr className="my-5 desktop" />}
                            {i % 2 === 0 && <hr className="my-5 mobile" />}
                          </>
                        );
                      })}
                    </div>

                    <div className="row p-4">
                      <div className="row h4">WHAT HAPPENS NEXT</div>
                      <div className="row my-2 h5">
                        As soon as your order is confirmed, we'll send you an
                        email.
                        <br />
                        Then we'll prepare your equipment. It will be ready for
                        pickup within 5 days of your departure date, or if you
                        chose to have it delivered, we'll send you an email when
                        it's shipped out.
                        <br />
                        <br />
                        Changing your rental dates may incur additional fees.
                        Please note all prices do not include Israeli VAT, and
                        your service is subject to our Terms & Conditions.
                        <br />
                        <br />
                        Sincerely,
                        <br />
                        TalknSave Confirmations Department
                      </div>
                    </div>
                    {/* <div className="row py-3">
              <div className="col-lg-12 col-12 ODFont1 c142454">
                {data.PlanDisplayName}
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">Equipment</div>
              <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                {data.EquipmentModel}
              </div>
            </div>
            <hr />

            {data.CallPackage !== 0 && (
              <>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                    Call Package
                  </div>
                  <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                    ${data.CallPackage}
                  </div>
                </div>
                <hr />
              </>
            )}
            {data.SMSPackage !== 0 && (
              <>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                    SMS Package
                  </div>
                  <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                    ${data.SMSPackage}
                  </div>
                </div>
                <hr />
              </>
            )}
            {data.KNTSignupFees !== 0 && (
              <>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                    Stay Local Signup Fee
                  </div>
                  <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                    ${data.KNTSignupFees}
                  </div>
                </div>
                <hr />
              </>
            )}
            {data.MonthlyServicePlan !== 0 && (
              <>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                    Monthly Service Plan
                  </div>
                  <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                    ${data.MonthlyServicePlan}
                  </div>
                </div>
                <hr />
              </>
            )}
            {data.InsuranceFee !== 0 && (
              <>
                <div className="row ">
                  <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">
                    Insurance
                  </div>
                  <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                    ${data.InsuranceFee}
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="row ">
              <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">Vat</div>
              <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                ${data.VAT?.toFixed(2)}
              </div>
            </div>
            <hr />
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-2 ODFont2 c142454">Total</div>
              <div className="col-lg-6 col-md-6 col-10 text-end ODFont3">
                ${data.GrandTotal}
              </div>
            </div>
            <hr /> */}
                  </div>

                  {/* Old Changes */}

                  {/* <div className="row desktop">
            <div className="col-lg-12 ">
              <div className="OSprogress-bar ">
                <div
                  id="progress"
                  className="OSprogress"
                  style={{ width: "65%" }}
                ></div>
                <div className="OSprogress-item active"></div>
                <div className="OSprogress-item active"></div>
                <div className="OSprogress-item "></div>
              </div>
            </div>
          </div> */}

                  {/* For Mobile */}

                  {/* <div className="row mobile">
            <div className="col-2"></div>
            <div className="OSprogressM-bar ">
              <div
                id="progress"
                className="OSprogressM"
                style={{ width: "65%" }}
              ></div>
              <div className="OSprogressM-item active"></div>
              <div className="OSprogress-item active"></div>
              <div className="OSprogress-item "></div>
            </div>
            <div className="col">
              <div className=" col-12 orderPlacedDesign">
                <div className="OSfont3">Order Placed</div>
                <div className="OSfont4">
                  {convertUnixToDateTime(orderData.StartDate).split(",")[1]}
                </div>
                <div className="OSfont4">
                  {convertUnixToDateTime(orderData.StartDate).split(",")[0]}
                </div>
              </div>
              <div className="shippedCenter col-12">
                <div className="OSfont3">Shipped</div>
              </div>
              <div className=" textright col-12">
                <div className="OSfont3">Delivered</div>
              </div>
            </div>
          </div>
          <div className="row p-3 desktop">
            <div className="col-lg-4 col-12">
              <div className="OSfont3">Order Placed</div>
              <div className="OSfont4">
                {convertUnixToDateTime(orderData.StartDate).split(",")[1]}
              </div>
              <div className="OSfont4">
                {convertUnixToDateTime(orderData.StartDate).split(",")[0]}
              </div>
            </div>
            <div className="col-lg-4 shippedCenter col-12">
              <div className="OSfont3">Shipped</div>
            </div>
            <div className="col-lg-4 textright col-12">
              <div className="OSfont3">Delivered</div>
            </div>
          </div>
          <div className="row p-3 ">
            <div className="col-lg col-6">
              <button className="OSYellowbtn">Edit</button>
            </div>
            <div className="col-lg col-6">
              <button className="OSYellowbtn">Add</button>
            </div>
            <div className="col-lg col-6">
              <button className="OSYellowbtn">Extend Rental</button>
            </div>
            <div className="col-lg col-6">
              <button className="OSYellowbtn">Change Plan</button>
            </div>
            <div className="col-lg col-12">
              {/* <button className="OSRedbtn">Cancle Order</button> 

              <PopupModal
                type="submit"
                value="Submit"
                className="btn btn OSRedbtn "
                btnName="Cancel Order"
                size="cancelOrderSize orderStatusDismissBtn"
              >
                <CancelOrderStatus />
              </PopupModal>
            </div>
          </div> */}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
