import { ApiGetCall } from "../Functions/connector";
import $ from "jquery";

export const ValidateData = async (data, bundleSubLinkData) => {
  let {
    minimumPeriod,
    bundles,
    maximumPeriod,
    insurance,
    optAllowMultipleRentals,
    Counter,
    ClientCode,
  } = bundleSubLinkData[0];
  let { kntCountries, smss } = bundles[0];
  var err_day = "";
  var isValidateDate = false;

  //ValidateBundle
  if (data.target.BundleData.value == "-1")
    return { isDateVisible: true, err_date_msg: "Please select a Bundle" };

  //ValidateEquipment
  if (data.target.equipmentData.value == "-1")
    return { isDateVisible: true, err_date_msg: "Please select an Equipment" };

  //ValidateInsurance
  if (insurance == "optional") {
    if (
      !document.getElementById("InsuranceRadioYes").checked &&
      !document.getElementById("InsuranceRadioNo").checked
    )
      return { isDateVisible: true, err_date_msg: "Please select Insurance" };
    else if (
      document.getElementById("InsuranceRadioNo").checked &&
      !data.target.NoInsuranceConfirmationCB.checked
    )
      return {
        isDateVisible: true,
        err_date_msg:
          "You have opted to decline insurance. Please make sure to confirm the implications of this choice in the relevant checkbox.",
      };
  }

  //ValidatekntCountries
  if (kntCountries?.length > 0) {
    if (data.target.kntCountries.value == "0")
      return { isDateVisible: true, err_date_msg: "Please select KeepnTouch" };
    else if (data.target.kntCountries.value != "-1") {
      //ValidateQuantity
      if (optAllowMultipleRentals) {
        if (
          parseInt(data.target.EquipmentQuantity.value) <
          parseInt(data.target.StayLocalNumberQuantity.value)
        )
          return {
            isDateVisible: true,
            err_date_msg: "Invalid KeepnTouch Quantity",
          };
      }
    }
  }

  if (smss?.length > 1) {
    if (data.target.smss.value == "-1")
      return { isDateVisible: true, err_date_msg: "Please select sms" };
  }

  //ValidateDates
  let begin_date = new Date(data.target.startDate.value);

  if (maximumPeriod == null) {
    if (begin_date == "Invalid Date")
      return { isDateVisible: true, err_date_msg: "Please Start Date" };
  } else {
    let end_date = new Date(data.target.endDate?.value);

    if (begin_date == "Invalid Date" || end_date == "Invalid Date")
      return { isDateVisible: true, err_date_msg: "Please select Dates" };

    let diffTime = Math.abs(end_date - begin_date);
    let days = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    days = days + 1;
    let month = days / 30;
    let minDaysOrMonth = minimumPeriod.split("|");
    let maxDaysOrMonth = maximumPeriod.split("|");

    minDaysOrMonth[0] = parseInt(minDaysOrMonth[0]);
    maxDaysOrMonth[0] = parseInt(maxDaysOrMonth[0]);

    if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "d") {
      if (days < minDaysOrMonth[0]) {
        err_day =
          "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        isValidateDate = true;
      } else if (days > maxDaysOrMonth[0]) {
        err_day =
          "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
        isValidateDate = true;
      } else {
        // $(parent).find(".error_day").text("");
      }
    } else if (minDaysOrMonth[1] == "d" && maxDaysOrMonth[1] == "m") {
      if (days < minDaysOrMonth[0]) {
        err_day =
          "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        isValidateDate = true;
      } else if (month > maxDaysOrMonth[0]) {
        err_day =
          "The maximum rental period for this group is " +
          maxDaysOrMonth[0] +
          " month.";
        isValidateDate = true;
      } else {
        // $(parent).find(".error_day").text("");
      }
    } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "m") {
      if (month < minDaysOrMonth[0]) {
        err_day =
          "The minimum rental period is " + maxDaysOrMonth[0] + " month.";
        isValidateDate = true;
      } else if (month > maxDaysOrMonth[0]) {
        err_day =
          "The maximum rental period is " + maxDaysOrMonth[0] + " month.";
        isValidateDate = true;
      } else {
        // $("#next1").attr("disabled", false);
        // $(parent).find(".error_day").text("");
      }
    } else if (minDaysOrMonth[1] == "m" && maxDaysOrMonth[1] == "d") {
      if (month < minDaysOrMonth[0]) {
        err_day =
          "The minimum rental period is " + minDaysOrMonth[0] + " month.";
        isValidateDate = true;
      } else if (days > maxDaysOrMonth[0]) {
        err_day =
          "The maximum rental period is " + maxDaysOrMonth[0] + " days.";
        isValidateDate = true;
      } else {
        // $("#next1").attr("disabled", false);
        // $(".error_day").text("");
      }
    } else {
      if (days < minDaysOrMonth[0]) {
        err_day =
          "The minimum rental period is " + minDaysOrMonth[0] + " days.";
        isValidateDate = true;
      }
    }
    if (isValidateDate)
      return { isDateVisible: isValidateDate, err_date_msg: err_day };
  }

  //ValidateUserDetails
  if (data.target.firstName.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter First Name" };

  if (data.target.lastName.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter Last Name" };

  if (
    !data.target.email.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return { isDateVisible: true, err_date_msg: "Please enter email address" };

  if (data.target.phoneNumber.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter phone number" };

  if (data.target.streetAddress.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter street address" };

  if (data.target.city.value == "")
    return { isDateVisible: true, err_date_msg: "Please enter city" };

  //ValidateBillingData
  if (data.target.billingFirstName.value == "")
    return {
      isDateVisible: true,
      err_date_msg: "Please enter cardholder first name !",
    };

  if (data.target.billingLastName.value == "")
    return {
      isDateVisible: true,
      err_date_msg: "Please enter cardholder last name !",
    };

  if (
    !data.target.billingEmail.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return {
      isDateVisible: true,
      err_date_msg: "Please enter valid Cardholder email !",
    };

  let ccNum = data.target.billingCardNumber.value.replaceAll(" ", "");
  if (isNaN(parseInt(ccNum))) {
    return {
      isDateVisible: true,
      err_date_msg: "Please enter valid cc number !",
    };
  } else {
    if (ccNum.length < 14) {
      return {
        isDateVisible: true,
        err_date_msg: "Incorrect credit card number!",
      };
    }
  }

  if (
    data.target.billingExpireMonth.value == "0" ||
    data.target.billingExpireYear.value == "0"
  )
    return {
      isDateVisible: true,
      err_date_msg: "Please select Expiration Date !",
    };

  if (data.target.billingCardType.value == "0")
    return {
      isDateVisible: true,
      err_date_msg: "Please select valid card type !",
    };

  //ValidateCountry
  let CountryCode = data.target.CountryData.value;
  if (CountryCode == "-1")
    return { isDateVisible: true, err_date_msg: "Please select the country!" };
  else {
    if (CountryCode == "1" || CountryCode == "7") {
      if (data.target.StateData.value == "-1")
        return {
          isDateVisible: true,
          err_date_msg: "Please enter the State/Province!",
        };
    }
  }

  if (CountryCode != "4") {
    if (data.target.ZipCode.value == "" || data.target.ZipCode.value == "0")
      return {
        isDateVisible: true,
        err_date_msg: "Please enter the zip!",
      };
  }

  //ValidateCoupon
  var Coupon = data.target.coupon.value;
  var isCouponValid = false;
  if (Coupon != "") {
    isCouponValid = await ApiGetCall(
      `/api/CouponCode?coupon=${parseInt(Coupon)}&b=${parseInt(
        bundles[0]?.Counter
      )}&linkid=${parseInt(Counter)}`
    ).then((result) => {
      try {
        if (result === undefined || result === "") {
          alert("Something went wrong");
        } else {
          const responseRs = JSON.parse(result);
          if (responseRs == "invalid") return true;
        }
      } catch (e) {
        console.log(e);
      } finally {
        //   setLoader(false);
      }
    });
  }
  if (isCouponValid)
    return {
      isDateVisible: true,
      err_date_msg: "Invalid coupon code !",
    };

  if (data.target.TNSPhone.value == "")
    return {
      isDateVisible: true,
      err_date_msg: "Please enter TNS/SIM Number#!",
    };

  if (optAllowMultipleRentals) {
    if (
      parseInt(data.target.EquipmentQuantity.value) > 1 &&
      data.target.TNSPhone.value.indexOf(";") == -1
    )
      return {
        isDateVisible: true,
        err_date_msg:
          "If you have some phones, please put ; between every Phone#!",
      };
  }
  if (ClientCode == 3) {
    if (data.target.TNSPhone.value.length < 18)
      return {
        isDateVisible: true,
        err_date_msg: "Please enter SIM Number#!",
      };
  }

  if (!data.target.authorizationCheckbox.checked)
    return {
      isDateVisible: true,
      err_date_msg:
        "You must agree to the Terms & conditions in order to submit this order!",
    };

  return { isDateVisible: false, err_date_msg: "" };
};

export const FillData = (data, bundleSubLinkData, shipData) => {
  var FillDataObject = [];
  var SBLData = bundleSubLinkData[0]; // bundleSubLinkData
  var BData = SBLData?.bundles[0]; // bundleData
  var ShipData = shipData[0]; // shipData
  var equipmentData = data.target.equipmentData.selectedOptions[0];

  var ob = new Object();
  ob.ContractType = SBLData?.contractType;
  ob.ProviderCode = SBLData?.ProviderCode;
  ob.txtSignupRep = "";
  ob.LinkTypeCode = SBLData?.LinkTypeCode;
  ob.AgentCode = SBLData?.AgentCode;
  ob.SubAgentCode = SBLData?.SubAgentCode;
  ob.bitCallPackageOverageProtection = false;
  ob.BundleId = BData?.Counter;

  var start_date = dateFormat(data.target.startDate.value);
  var end_date =
    data.target.endDate?.value != undefined
      ? dateFormat(data.target.endDate?.value)
      : "";
  start_date = start_date + " 12:00:00 AM";
  end_date = end_date + " 12:00:00 AM";

  // var europePlanSelection = "";
  // var euroIDAndQty = "";
  // try {
  //   var euroPlanName = $(this).attr("europeplanname");
  //   var checkBoxId = $(this).attr("europecheckboxid");
  //   //	var euroQuantity=$(this).attr('quantity');
  //   var euroQuantity = "1";

  //   if (checkBoxId != undefined && checkBoxId != null) {
  //     euroIDAndQty = " " + checkBoxId + "-" + euroQuantity;
  //     europePlanSelection =
  //       " " + euroPlanName + " " + checkBoxId + "-" + euroQuantity;
  //   }
  // } catch (err) {
  //   console.log("europeError");
  //   console.log(err);
  // }

  // if (counter == 1) {
  //   ob.AccessoryIdAndQuantity = strAccessoryIdAndQuantity + "" + euroIDAndQty;
  // } else {
  //   ob.AccessoryIdAndQuantity = euroIDAndQty;
  // }

  ob.txtSignupRep = "";
  ob.ccemail = data.target.billingEmail.value;
  ob.SessionID = "NC_";
  ob.BaseCode = ShipData?.BaseCode ? ShipData?.BaseCode : 100;
  ob.BaseNotes = ShipData?.shippingNotes;
  ob.bitCallPackageOverageProtection = false;
  ob.CallPackageCode = BData?.CallPackageCode;

  ob.CallPackageName = BData?.CallPackageName;
  ob.CCCode = null;
  ob.strCCExpDate =
    data.target.billingExpireMonth.value +
    "/" +
    data.target.billingExpireYear.value; // time and date not included

  ob.CCNum = data.target.billingCardNumber.value.replaceAll(" ", "");
  ob.CCTitle = data.target.billingCardType.value;
  ob.ClientCity = data.target.city.value;
  ob.ClientCountry =
    data.target.CountryData.selectedOptions[0].getAttribute("data-countryname");
  ob.clientemail = data.target.email.value;
  ob.ClientFirstName = data.target.firstName.value;
  ob.ClientHomePhone1 = data.target.phoneNumber.value;
  ob.ClientHomePhone2 = "0";
  ob.ClientIP = ":1";
  ob.ClientLastName = data.target.lastName.value;
  ob.ClientMobile = data.target.phoneNumber.value;

  let CountryCode = data.target.CountryData.value;
  ob.ClientState =
    CountryCode == "1" || CountryCode == "7"
      ? data.target.StateData.value
      : "NA";

  ob.ClientStreet = data.target.streetAddress.value;
  ob.ClientZip = CountryCode != "4" ? data.target.ZipCode.value : "0";

  ob.CompanyCode = SBLData?.CompanyCode;
  ob.PageName = "";
  ob.CouponCode = data.target.coupon.value;
  ob.CreditEquipmentPurchase = null;
  ob.CustomerComment = data.target.ccNote.value;
  ob.DataPackageCode = BData?.ExtendedDataPackageCode;
  ob.DataPackageId = ob.DataPackageCode;
  ob.DataPackageName = BData?.ExtendedDataPackageName;
  ob.DataPackgeSize = BData?.PackageSize;
  ob.DepartureDate = start_date;
  ob.Deposit = SBLData?.DepositAmount;

  let maximumPeriod = SBLData?.maximumPeriod;

  let begin_date = dateFormat(data.target.startDate.value);
  let end_year = new Date(begin_date).getFullYear();
  let end_month = new Date(begin_date).getMonth();
  let end_day = new Date(begin_date).getDate();
  let endDate = new Date(end_year + 10, end_month, end_day);

  let endDateStr =
    (endDate.getMonth() > 8
      ? endDate.getMonth() + 1
      : "0" + (endDate.getMonth() + 1)) +
    "/" +
    (endDate.getDate() > 9 ? endDate.getDate() : "0" + endDate.getDate()) +
    "/" +
    endDate.getFullYear();

  endDateStr = endDateStr + " 12:00:00 AM";
  //ob.EndDate= maximumPeriod !=="" ? endDateStr : end_date ;
  if (
    (maximumPeriod == "" || maximumPeriod == undefined) &&
    (end_date == "" || end_date == undefined)
  ) {
    ob.EndDate = endDateStr;
  } else {
    ob.EndDate = end_date;
  }

  ob.EquipmentCode = equipmentData?.getAttribute("data-code");
  ob.EquipmentModel = ob.EquipmentCode;
  ob.EquipmentName = equipmentData?.getAttribute("data-name");
  ob.EquipmentNotes = equipmentData?.getAttribute("data-note");
  ob.FirstName = data.target.firstName.value;
  ob.GroupMemberID = "";
  ob.GroupName = SBLData?.GroupName ? SBLData?.GroupName : null;
  ob.Hint = null;
  ob.Insurance =
    SBLData?.insurance == "included" ||
    SBLData?.insurance == "required" ||
    document.getElementById("InsuranceRadioYes").checked
      ? true
      : false;
  ob.IsEquipmentSNS = equipmentData?.getAttribute("data-issns") == "true";
  ob.IsKosher = equipmentData?.getAttribute("data-kosher") == "true";
  ob.IsRequierdOperationSys = false;
  ob.IsSim = equipmentData?.getAttribute("data-issim") == "true";
  ob.IsSmartPhone = equipmentData?.getAttribute("data-issmartphone") == "true";
  ob.KITD = false;
  ob.KITD_BLOCK_ID = null;
  ob.KITD_PlanCode = -1;
  ob.KNTName = "";
  ob.KNTRequired = -1;
  ob.LanguageCode = 1;
  ob.LastName = data.target.lastName.value;
  ob.ParentLink = BData?.ParentLink;
  ob.ParentOnlineOrderCode = null;

  ob.PhonesRequired = SBLData?.optAllowMultipleRentals
    ? parseInt(data.target.EquipmentQuantity.value)
    : 1;
  ob.PlanCode = BData?.PlanCode;
  ob.PlanName = BData?.PlanName;
  ob.ProductId = 1;
  ob.PurchaseEquipment = SBLData?.optPurchase;
  ob.ReferrerCounter = null;
  ob.ReferrerEmail = "";
  ob.RentalCode = null;
  ob.SetupFeeText = "No";
  ob.ShipCity =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "[group]"
      : ShipData?.optLocalPickup
      ? "[pickup]"
      : "";
  ob.ShipCommercial = false;
  ob.ShipCountry = ShipData?.CountryName ? ShipData?.CountryName : "";
  ob.ShipDate = start_date;
  ob.shipemail = data.target.email.value;
  ob.ShipFee =
    ob.PhonesRequired > 1
      ? parseFloat(ShipData?.cost) * 0.75
      : parseFloat(ShipData?.cost);
  ob.ShipId = ShipData?.shippingID;
  ob.ShipMethod = ShipData?.shippingMethodNEW
    ? ShipData?.shippingMethodNEW
    : "";
  ob.ShipName =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "[group]"
      : ShipData?.optLocalPickup
      ? "[pickup]"
      : "";
  ob.ShipPhone =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "0"
      : ShipData?.optLocalPickup
      ? "0"
      : "";

  ob.ShippingName = ShipData?.ShippingName;
  ob.ShipPostalCode =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "[group]"
      : ShipData?.optLocalPickup
      ? "[pickup]"
      : "";
  ob.ShipState =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "NA"
      : ShipData?.optLocalPickup
      ? "NA"
      : "";
  ob.ShipStreet =
    ShipData?.shippingMethodNEW == "GRP_DELIVERY"
      ? "[group]"
      : ShipData?.optLocalPickup
      ? "[pickup]"
      : "";

  ob.SMSPackageCode = BData?.SMSPackageCode;
  ob.SMSPackageCounter = ob.SMSPackageCode;
  ob.SMSPackageName = BData?.SMSPackageName;
  ob.Special = SBLData?.LinkTypeCode == 110 ? true : false;
  ob.StartDate = start_date;
  ob.SubLink = SBLData?.SubLink;
  ob.SublinkId = SBLData.Counter;
  ob.SurfAndSave = false;

  // let tags = "";
  // let countAlreadySim = 0;
  // let simString = "";
  // $(".simCount").each(function (index) {
  //   let simValue = $(this).find("input:checked").val();

  //   if (simValue == "9999") {
  //     countAlreadySim++;
  //   }
  // });
  // if (countAlreadySim > 0) {
  //   		   $('.simNoclone').each(function (index) {
  //   			   let simCardNum = $(this).find('input').val();
  //   			 simCardNum = simCardNum.replace(/\s/g, '');
  //   			   simString+= (index+1) +' '+'Special Order:Sim Number#: 89972' +simCardNum + '#' + '    ';
  //   		   });
  // }

  // var alreadyHaveSimNums = $(this).attr("simnumbers");
  // if (
  //   alreadyHaveSimNums != undefined &&
  //   alreadyHaveSimNums != "" &&
  //   alreadyHaveSimNums != " "
  // ) {
  //   simString = alreadyHaveSimNums;
  // }
  // if (counter == 1) {
  //   ob.Tag = simString + tags + europePlanSelection;
  // } else {
  //   ob.Tag = simString + europePlanSelection;
  // }

  ob.TermsCode = -1;
  ob.TermsName = null;
  ob.UserName = data.target.firstName.value + " " + data.target.lastName.value;
  ob.UserStreet = SBLData?.AdminComment;

  var simArr = [];

  var SimDetails = new Object();
  SimDetails.KITD_PlanCode =
    data.target.kntCountries?.value != undefined
      ? parseFloat(data.target.kntCountries?.value)
      : 0;
  SimDetails.SMSPackageName =
    data.target.smss?.selectedOptions[0]?.getAttribute("data-name")
      ? data.target.smss?.selectedOptions[0]?.getAttribute("data-name")
      : "";

  if (SimDetails.KITD_PlanCode != -1) {
    SimDetails.KITD = true;
  } else {
    SimDetails.KITD = false;
  }

  SimDetails.CallPackageCode = ob.CallPackageCode;
  SimDetails.curST_TOP_Price = 0.0;
  SimDetails.DataPackageCode = ob.DataPackageCode;
  SimDetails.DataPackageId = ob.DataPackageCode;
  SimDetails.DataPackageName = ob.DataPackageName;
  SimDetails.DataPackgeSize = ob.DataPackgeSize;
  SimDetails.decST_TOP_GB = 0.0;
  SimDetails.EquipmentCode = equipmentData?.getAttribute("data-code");
  SimDetails.EquipmentModel = equipmentData?.getAttribute("data-code");
  SimDetails.EquipmentName = equipmentData?.getAttribute("data-name");
  SimDetails.EquipmentNotes = equipmentData?.getAttribute("data-note");
  SimDetails.Insurance =
    SBLData?.insurance == "included" ||
    SBLData?.insurance == "required" ||
    document.getElementById("InsuranceRadioYes").checked
      ? true
      : false;

  var startDt = dateFormat(data.target.startDate.value);
  var endDt =
    data.target.endDate?.value != undefined
      ? dateFormat(data.target.endDate?.value)
      : "";

  SimDetails.StartDate = startDt + " 12:00:00 AM";
  SimDetails.DepartureDate = startDt + " 12:00:00 AM";

  let begin_date2 = startDt;
  let end_year2 = new Date(begin_date2).getFullYear();
  let end_month2 = new Date(begin_date2).getMonth();
  let end_day2 = new Date(begin_date2).getDate();
  let endDate2 = new Date(end_year2 + 10, end_month2, end_day2);

  let endDateStr2 =
    (endDate2.getMonth() > 8
      ? endDate2.getMonth() + 1
      : "0" + (endDate2.getMonth() + 1)) +
    "/" +
    (endDate2.getDate() > 9 ? endDate2.getDate() : "0" + endDate2.getDate()) +
    "/" +
    endDate2.getFullYear();

  endDateStr2 = endDateStr2 + " 12:00:00 AM";
  if (
    (maximumPeriod == "" || maximumPeriod == undefined) &&
    (endDt == "" || endDt == undefined)
  ) {
    SimDetails.EndDate = endDateStr2;
  } else {
    SimDetails.EndDate = endDt;
  }

  //SimDetails.EndDate = endDt + " 12:00:00 AM";
  SimDetails.Img = "https://www.talknsave.us/images/OneSimForall.jpg";
  // SimDetails.Insurance = (insurance) ? true : false;

  var isSns2 = equipmentData?.getAttribute("data-issns");
  var isKosher2 = equipmentData?.getAttribute("data-kosher");
  var isSim2 = equipmentData?.getAttribute("data-issim");
  SimDetails.IsEquipmentSNS = isSns2 == "true" ? true : false;
  SimDetails.IsKosher = isKosher2 == "true" ? true : false;
  SimDetails.IsRequiredOperationSystem = false;
  SimDetails.IsSIM = isSim2 == "true" ? true : false;

  var smsPkgCodeAndCounter = ob.SMSPackageCode;
  try {
    var smsPackakeCode = data.target.smss.value;
    if (
      smsPackakeCode != undefined &&
      smsPackakeCode != "" &&
      smsPackakeCode != "0"
    ) {
      smsPkgCodeAndCounter = smsPackakeCode;
    }
  } catch (err) {
    smsPkgCodeAndCounter = BData?.SMSPackageCode;
  }
  SimDetails.SMSPackageCode = smsPkgCodeAndCounter;
  SimDetails.SMSPackageCounter = smsPkgCodeAndCounter;
  simArr.push(SimDetails);

  ob.SimDetails = simArr;

  let counter = 1;
  let strAccessoryIdAndQuantity = "";
  let tags = "";
  ob.optionalOrders = new Object();
  ob.AccessoryIdAndQuantity = "";
  ob.Tag = "";

  tags =
    `  Special Order:${
      SBLData?.ClientCode == 3 ? "Sim Number#: " : "TNS Number#: "
    }` +
    data.target.TNSPhone.value.replaceAll(";", "   ") +
    " #   ";

  var optionalOrderArray = [];
  $(".phonecard").each(function () {
    if ($(this).find('input[name="ifOptional"]').is(":checked")) {
      let OptionalValue = $(this).find('input[name="ifOptional"]');
      let OptionalQty = $(this).find('input[name="OptionalQty"]').val();
      let ClientCode = OptionalValue.attr("clientcode");
      let CouponCode = OptionalValue.attr("couponcode");
      let Deposit = OptionalValue.attr("deposit");
      let EquipmentCode = OptionalValue.attr("equipmentcode");
      let Insurance = OptionalValue.attr("insurance");
      let OptionalCode = OptionalValue.attr("optionalcode");
      let OptionalFeeDesc = OptionalValue.attr("optionalfeedesc");
      let OptionalImg = OptionalValue.attr("optionalimg");
      let OptionalName = OptionalValue.attr("optionalname");
      let PlanCode = OptionalValue.attr("plancode");
      let RequiredInsurance = OptionalValue.attr("requiredinsurance");
      let RequiredOperationSystem = OptionalValue.attr(
        "requiredoperationsystem"
      );
      RequiredInsurance = RequiredInsurance.trim();
      RequiredOperationSystem = RequiredOperationSystem.trim();
      var newObject = new Object();
      newObject.ClientCode = $.trim(ClientCode);
      newObject.CouponCode = $.trim(CouponCode);
      newObject.Deposit = $.trim(Deposit);
      newObject.Insurance = Insurance == "true" ? true : false;
      newObject.OptionalCode = $.trim(OptionalCode);
      newObject.OptionalFeeDesc = $.trim(OptionalFeeDesc);
      newObject.OptionalImg = $.trim(OptionalImg);
      newObject.OptionalName = $.trim(OptionalName);
      newObject.PlanCode = $.trim(PlanCode);
      newObject.Quantity = OptionalQty;
      newObject.EquipmentCode = $.trim(EquipmentCode);
      newObject.RequiredInsurance = RequiredInsurance == "true" ? true : false;
      newObject.RequiredOperationSystem =
        RequiredOperationSystem == "true" ? true : false;
      optionalOrderArray.push(newObject);

      if (counter == 1) {
        if (parseInt(OptionalQty) > 0) {
          let quantity = "";
          let insurance = "";

          if (parseInt(OptionalQty) > 1) {
            quantity = "(Quantity:" + OptionalQty + ")";
          }
          if (Insurance) {
            insurance = "Insuarance:" + true;
          }
          if (!(parseInt(PlanCode) > -1 && parseInt(EquipmentCode) > -1)) {
            strAccessoryIdAndQuantity += OptionalCode + "-" + OptionalQty + ",";
          }

          tags +=
            OptionalName +
            " " +
            quantity +
            insurance +
            strAccessoryIdAndQuantity;
        }
      }
    }
    counter++;
  });

  if (optionalOrderArray.length > 0) {
    ob.optionalOrders = optionalOrderArray;
    ob.AccessoryIdAndQuantity = strAccessoryIdAndQuantity;
  }
  ob.Tag = tags;

  ob.newsletter = false;
  ob.UserCity = "NA";
  // console.log(ob);
  FillDataObject.push(ob);

  return FillDataObject;
};

const dateFormat = (date) => {
  var dates = date.split("-");
  return `${dates[1]}/${dates[2]}/${dates[0]}`;
};
