import React from "react";
import "./rentalcss.css";
import "../rentalPage_popup/rentalPage_popup.css";
import PopupModal from "../Common/Popup";
import AddNewCard from "../Bills/addNewCard";
import ExtendRental from "../rentalPage_popup/extendRental";
import AllDataPopup from "../rentalPage_popup/allDataPopup";
class RentalLargeCom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="col-lg-2 col-md-2 col-2 setImagepadding">
          <div className="RimgBox useageBox">
            <img src={this.props.img} alt="phone" />
          </div>
        </div>
        <div className="col-lg-10 col-md-10 col-10">
          <div className="d-flex spaceBtw">
            <div className="LargeFont1 fl">{this.props.data1}</div>
            <div className="col"></div>
            <div className="LargeFont2 fr">{this.props.data2}</div>
          </div>
          <div className="row pt-3">
            <div className="col-4">
              {this.props.popup == "CCpopup" ? (
                <>
                  <div className={this.props.popup}>
                    <PopupModal
                      type="submit"
                      value="Submit"
                      className=""
                      class="LargeFont3"
                      btnName="Edit"
                      size="addNewCardSize deleteCardDismissBtn"
                    >
                      <AddNewCard
                        cnumber={this.props.CardNum}
                        RcoDe={this.props.CardRentalCode}
                        title="Update Payment Method"
                      />
                    </PopupModal>
                  </div>
                </>
              ) : this.props.popup == "ExtendRentalpopup" ? (
                <>
                  <div className={this.props.popup}>
                    <PopupModal
                      type="submit"
                      value="Submit"
                      className=""
                      class="LargeFont3"
                      btnName="Extend Rental"
                      size="ExtendRentalCardSize deleteCardDismissBtn"
                      id="ExtendRentalPopup"
                    >
                      <ExtendRental
                        RcoDe={this.props.CardRentalCode}
                        EDate={this.props.eDate}
                      />
                    </PopupModal>
                  </div>
                </>
              ) : this.props.popup == "DataUsagepopup" ? (
                <>
                  <div className={this.props.popup}>
                    <PopupModal
                      type="submit"
                      value="Submit"
                      className=""
                      class="LargeFont3"
                      btnName="Add Data"
                      size="ExtendRentalCardSize deleteCardDismissBtn addDataPopup"
                    >
                      <AllDataPopup
                        RcoDe={this.props.CardRentalCode}
                        EDate={this.props.eDate}
                        rentalData={this.props.rentalData}
                      />
                    </PopupModal>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className={`col-8 ${this.props.DisplayNone}`}>
              <div className="progress">
                <div
                  className="progress-bar pb "
                  role="progressbar"
                  aria-valuenow="75"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: `${this.props.progressData}%` }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RentalLargeCom;
