import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./freezeSimModal.css";

class PopupModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  componentDidMount(){
    if(this.props.dfToken != undefined && this.props.dfToken != null && this.props.dfToken !=""){
      this.setState({ show: true });
    }
  }
  handleModalshow() {
    this.setState({ show: !this.state.show });
  }
  render() {
    return (
      <>
        <div className={`modalClass ${this.props.className}`}>
          <Button
            className={this.props.class}
            onClick={() => this.handleModalshow()}
            id={this.props.id}
          >
            {this.props.btnName}
          </Button>
        </div>

        <Modal
          className={`${this.props.size} `}
          show={this.state.show}
          onHide={() => this.handleModalshow()}
          backdrop={
            this.props.backdrop == null || this.props.backdrop == undefined
              ? "true"
              : this.props.backdrop
          }
        >
          {/* <Modal.Header closeButton>This is a Modal Heading</Modal.Header>   */}

          <Modal.Body>
            <button
              type="button"
              className="btn closebtn2"
              id="closebtn2"
              aria-label="Close"
              onClick={() => this.handleModalshow()}
            >
              x
            </button>
            {this.props.children}
          </Modal.Body>
          {/* <Modal.Footer> */}
          {/* {!this.props.isbtnSim && (
              <Button onClick={() => this.handleModalshow()}>Close</Button>
            )} */}
          {/* {this.props.isbtnSim && (
              <>
                <Button onClick={() => this.handleModalshow()}>Close</Button>
              </>
            )} */}
          {/* <Button onClick={()=>this.handleModalshow()}>Save</Button>   */}
          {/* </Modal.Footer> */}
        </Modal>
      </>
    );
  }
}
export default PopupModal;
