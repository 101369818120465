import React, { useEffect } from "react";
import "./style.css";
import { ApiPostCall } from "../Functions/connector";
import { useState } from "react";
import { convertUnixToDateTime } from "../Helper/HelperFun";
import $ from "jquery";
import LPPopup from "./LPPopup";
import AssignPopup from "./AssignPopup";

export default function AssignOrders() {
  const [recentOrderData, setRecentOrderData] = useState([]);
  const [Loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [successData, setSuccessData] = useState({});
  const [totalOrder, setTotalOrder] = useState(0);

  const Token = window.localStorage.getItem("Token");
  const UserData = window.localStorage.getItem("userData");
  const { UserName, ShippingId, ViewOnlyPickups, AgentEmail } =
    JSON.parse(UserData)[0];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    var urlencoded = new URLSearchParams();
    console.log("ShippingId",ShippingId.toString());
    urlencoded.append("ShipId", ShippingId.toString());
    urlencoded.append("ViewOnlyPickups", ViewOnlyPickups);
    urlencoded.append("AgentName", UserName);
    urlencoded.append("Token", Token);
    ApiPostCall("/RouterParserLocationPortal/RecentOrders", urlencoded).then(
      (result) => {
        try {
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = JSON.parse(result);
            if (responseRs?.Data != "No data Found") {
              console.log(JSON.parse(responseRs.Data)[0]);
              setRecentOrderData(JSON.parse(responseRs.Data)[0]);
            } else {
              setRecentOrderData([]);
            }
          }
        } catch (e) {
          console.log(e);
        } finally {
          setLoader(false);
        }
      }
    );
  };

  const Submit = (e, i, data) => {
    e.preventDefault();
   
    var EquipmentAssigned = e.target.EquipmentAssigned.value;
    // console.log("EquipmentAssigned",data.EquipmentCode)
    var EquipmentCode = data.EquipmentCode;
    var isUsaSim = data?.isUsaSim;
    var isSFL = data?.isSFL;
    var PhoneModel = "";
    var IMEI = "";
    $(".btn" + i).prop("disabled", true);
    $(".EquipmentAssigned-Error" + i).text("");
    $(".PhoneModel-Error" + i).text("");
    $(".IMEI-Error" + i).text("");
    $(".loaderCustom" + i).addClass("d-none");

    var shouldSIM = false;
    var ifValid = true;
    if (isSFL || isUsaSim) shouldSIM = true;
    if (EquipmentAssigned == "") {
      ifValid = false;
      $(".EquipmentAssigned-Error" + i).text("Please enter a SIM Number");
    } else if (EquipmentAssigned.length < 18 && shouldSIM) {
      ifValid = false;
      $(".EquipmentAssigned-Error" + i).text("Please enter a SIM Number");
    }
    // PhoneModel = e.target.PhoneModel.value;
    // IMEI = e.target.IMEI.value;
    //if (ShippingId == 391 && data?.UserStreet != "BHLT") {
      if (ShippingId == 391) {
        PhoneModel = e.target.PhoneModel.value;
        IMEI = e.target.IMEI.value;
        // if (PhoneModel == "") {
        //   ifValid = false;
        //   $(".PhoneModel-Error" + i).text("Please fill the Phone Model");
        // }
       //
        if (IMEI && IMEI.length < 14 || IMEI.length > 18) {
          ifValid = false;
          $(".IMEI-Error" + i).text("Please fill valid IMEI Number");
        }
        if(EquipmentCode == 3070 && !IMEI && !PhoneModel){
          ifValid = false;
          $(".IMEI-Error" + i).text("Please fill valid IMEI Number");
          $(".PhoneModel-Error" + i).text("Please fill the Phone Model");
        }
        // } else {
        //   $(".IMEI-Error" + i).text("Please fill the IMEI Number");
        // }
      }
    if (!ifValid) {
      $(".btn" + i).prop("disabled", false);
      return;
    } else {
      var urlencoded = new URLSearchParams();
      urlencoded.append("ShipId", ShippingId.toString());
      urlencoded.append("ViewOnlyPickups", ViewOnlyPickups);
      urlencoded.append("AgentName", UserName);
      urlencoded.append("OrderCode", data?.OrderCode);
      urlencoded.append("SignupSourceKey", data?.SignupSourceKey);
      urlencoded.append("TxtNewPhone", EquipmentAssigned);
      urlencoded.append("TxtModel", data?.EquipmentName);
      urlencoded.append(
        "CustomerName",
        data?.ClientFirstName + " " + data?.ClientLastName
      );
      urlencoded.append("IsUsaSim", isUsaSim);
      urlencoded.append("IsSFL", isSFL);
      urlencoded.append("ImeiNumber", IMEI);
      urlencoded.append("PhoneModel", PhoneModel);
      urlencoded.append("AgentEmail", AgentEmail);
      urlencoded.append("Token", Token);
      console.log("urlencoded",urlencoded);
      $(".loaderCustom" + i).removeClass("d-none");
      ApiPostCall(
        "/RouterParserLocationPortal/SubmitRecentOrders",
        urlencoded
      ).then((result) => {
        try {   
          if (result === undefined || result === "") {
            alert("Something went wrong");
          } else {
            const responseRs = JSON.parse(result);
            if (responseRs?.Status == "Success") {
              setSuccessData(responseRs);
              $(".KNTAssigned" + i).val(responseRs?.Data?.KNTAssigned);
              $(".PhoneAssigned" + i).val(responseRs?.Data?.PhoneAssigned);
              $(".btn" + i).prop("disabled", true);
              setTotalOrder((totalOrder) => totalOrder + 1);
              // getData();
            } else {
              setSuccessData(responseRs);
              $(".btn" + i).prop("disabled", false);
            }
            setShow(true);
          }
        } catch (e) {
          console.log(e);
        } finally {
          $(".loaderCustom" + i).addClass("d-none");
        }
      });
    }
  };
  return (
    <React.Fragment>
      {Loader ? (
        <>
          <span className="d-none isGotData">0</span>
          <div className="loader_positon text-center">
            <img className="loader2 " src="/svgs/loader.svg" alt="eyeIcon" />
            <div className="" style={{ fontSize: "16px", fontWeight: "600" }}>
              Loading...
            </div>
          </div>
        </>
      ) : (
        <div className="pageMargin">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="recentOrderContainer p-4">
                <div className="row">
                  <div className="col-12">
                    <div className="boxCircle">
                      <img src="./images/LocationPortal/box.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div className="row my-2">
                  <div className="col-12 ro-font1 my-1 d95b08">
                    Total new orders
                  </div>
                  <div className="col-12 ro-font1 my-1 c142454">
                    {recentOrderData.length - totalOrder}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-12 ro-font1">
              Scan/Enter Equipment then click submit.
            </div>
          </div>

          {recentOrderData?.map((data, i) => {
            return (
              <>
                <div className="row my-3">
                  <div className="col-md-12 col-12">
                    <div className="recentOrderContainer px-4 py-3">
                      <div
                        className="row"
                        key={i}
                        style={{ borderBottom: "1px solid #E2E2E2" }}
                      >
                        <div className="col-md-6 col-6">
                          <div className="row">
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">Code</div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {data?.Code}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  Start Date
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {
                                    convertUnixToDateTime(
                                      data?.StartDate
                                    ).split(",")[0]
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">End Date</div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {
                                    convertUnixToDateTime(data?.EndDate).split(
                                      ","
                                    )[0]
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  Order Date
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {
                                    convertUnixToDateTime(
                                      data?.RecordCreationDate
                                    ).split(",")[0]
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-6">
                          <div className="row">
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  Departure Date
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {
                                    convertUnixToDateTime(
                                      data?.DepartureDate
                                    ).split(",")[0]
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  First Name
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {data?.ClientFirstName}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  Last Name
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {data?.ClientLastName}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 col-12 p-2">
                              <div className="row">
                                <div className="col-12 ro-font2 ">
                                  Equipment
                                </div>
                                <div className="col-12 ro-font3 mt-2 c142454">
                                  {data?.EquipmentName}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <form onSubmit={(e) => Submit(e, i, data)}>
                        <div className="row">
                          <div className="col-md-9 col-12">
                            <div className="row">
                              <div
                                className={`${ShippingId == 391 ? "cus-md-2" : "col-md-4"
                                  } col-12 p-2`}
                              >
                                <div className="row">
                                  <div className="col-12 ro-font4 c142454 pe-0">
                                    Equipment Assigned
                                  </div>
                                  <div className="col-12 mt-2">
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="EquipmentAssigned"
                                    />
                                    <span
                                      className={`mt-2 text-danger EquipmentAssigned-Error${i}`}
                                    ></span>
                                  </div>
                                </div>
                              </div>

                              {ShippingId == 391 && (
                                <>
                                  <div className="cus-md-2 col-12 p-2">
                                    <div className="row">
                                      <div className="col-12 ro-font4 c142454">
                                        IMEI
                                      </div>
                                      <div className="col-12 mt-2">
                                        <input
                                          className="form-control"
                                          type="number"
                                          name="IMEI"
                                          disabled={data.EquipmentCode === 1580 || data.EquipmentCode === 3030}
                                        />
                                        <span
                                          className={`mt-2 text-danger IMEI-Error${i}`}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="cus-md-2 col-12 p-2">
                                    <div className="row">
                                      <div className="col-12 ro-font4 c142454">
                                        Phone Model
                                      </div>
                                      <div className="col-12 mt-2">
                                        <input
                                          className="form-control"
                                          type="text"
                                          name="PhoneModel"
                                          disabled={data.EquipmentCode === 1580 || data.EquipmentCode === 3030}
                                        />
                                        <span
                                          className={`mt-2 text-danger PhoneModel-Error${i}`}
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div
                                className={`${ShippingId == 391 ? "cus-md-2" : "col-md-4"
                                  } col-12 p-2`}
                              >
                                <div className="row">
                                  <div className="col-12 ro-font4 c142454">
                                    KNT Assigned
                                  </div>
                                  <div className="col-12 mt-2">
                                    <input
                                      className={`form-control KNTAssigned${i}`}
                                      type="text"
                                      // value={successData?.Data?.KNTAssigned}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${ShippingId == 391 ? "cus-md-2" : "col-md-4"
                                  } col-12 p-2`}
                              >
                                <div className="row">
                                  <div className="col-12 ro-font4 c142454">
                                    Phone Assigned
                                  </div>
                                  <div className="col-12 mt-2">
                                    <input
                                      className={`form-control PhoneAssigned${i}`}
                                      type="text"
                                      // value={successData?.Data?.PhoneAssigned}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <div className="row">
                              <div
                                className="col-md-6 col-12 p-2"
                                style={{ alignSelf: "center" }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        defaultChecked={data?.isUsaSim}
                                        name="isUsaSim"
                                        disabled
                                      />
                                      <label
                                        className="form-check-label ro-font4 c142454"
                                        htmlFor="flexCheckDefault"
                                      >
                                        USA SIM
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                        id="flexCheckDefault"
                                        defaultChecked={data?.isSFL}
                                        name="isSFL"
                                        disabled
                                      />
                                      <label
                                        className="form-check-label ro-font4 c142454"
                                        htmlFor="flexCheckDefault"
                                      >
                                        SFL
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12"></div>
                                </div>
                              </div>
                              <div
                                className="col-md-6 col-12 p-2 pb-0"
                                style={{ alignSelf: "end" }}
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <button
                                      type="submit"
                                      className={`btn ro-button btn-d-flex py-2 w-100 btn${i}`}
                                    >
                                      Submit
                                      <span
                                        className={`loaderCustom${i} d-none`}
                                      >
                                        <img
                                          className="ms-2"
                                          src="/svgs/loader.svg"
                                          alt="eyeIcon"
                                          style={{ width: "70%" }}
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          {recentOrderData.length <= 0 && (
            <>
              <div className="row my-3">
                <div className="col-md-12 col-12">
                  <div className="recentOrderContainer px-4 py-3">
                    <div className="ro-font1 my-1 c14245">No data found!</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <LPPopup show={show}>
        <AssignPopup successData={successData} setShow={setShow} />
      </LPPopup>
    </React.Fragment>
  );
}
