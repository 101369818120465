import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PersonalDetailsValidation } from "../Yup Validation/YupValidations";
import AccountDetails from "./ApiControll";

export default function PersonalDetails() {
  const [PSdetails, setPSdetails] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    C_email: "",
    Phone: "",
    Address: "",
    City: "",
    Country: "",
    State: "",
    Zip: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(PersonalDetailsValidation),
  });
  useEffect(() => {
    AccountDetails().then((result) => {
      if (result === undefined || result === "") {
        alert("Something went wrong");
      } else {
        const results = JSON.parse(result);
        const datas = results[0][0];
        setPSdetails({
          ...PSdetails,
          FirstName: datas.ClientFirstName,
          LastName: datas.ClientLastName,
          Email: datas.ClientEMail,
          C_email: datas.ClientEMail,
          Phone: datas.ClientHomePhone1,
          Address: datas.ClientStreet,
          City: datas.ClientCity,
          Country: datas.ClientCountry,
          State: datas.ClientState,
          Zip: datas.ClientZip,
        });
        reset(datas);
      }
    });
  }, [reset]);
  const submitForm = (data, e) => {
    // console.log(e.target[0].value);
  };
  return (
    <div className="Form">
      <form onSubmit={handleSubmit(submitForm)}>
        <div className="form-group mt-2">
          <label htmlFor="input-1">First Name</label>
          <input
            disabled
            type="text"
            name="firstName"
            id="PSfirstname"
            className="form-control"
            {...register("firstName", { value: PSdetails.FirstName })}
            placeholder="Enter Your First Name"
          />
          <p className="text-danger"> {errors.firstName?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-2">Last Name</label>
          <input
            disabled
            type="text"
            name="lastname"
            id="PSlastname"
            className="form-control"
            placeholder="Enter Your Last Name"
            {...register("lastname", { value: PSdetails.LastName })}
          />
          <p className="text-danger"> {errors.lastname?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-3">Email</label>
          <input
            disabled
            type="email"
            name="email"
            id="PSemail"
            className="form-control"
            placeholder="Enter Your Email Address"
            {...register("email", { value: PSdetails.Email })}
          />
          <p className="text-danger"> {errors.email?.message} </p>
        </div>
        {/* <div className="form-group mt-2">
          <label htmlFor="input-3">Confirm Email</label>
          <input
            
            type="email"
            name="C_email"
            id="PSC_email"
            className="form-control"
            placeholder="Enter Your Email Address"
            {...register("C_email", { value: PSdetails.C_email })}
          />
          <p className="text-danger">
            {" "}
            {errors.C_email?.message ===
            "C_email must be one of the following values: , Ref(email)"
              ? "Email and Confirm Email must be same."
              : errors.C_email?.message}{" "}
          </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">Phone</label>
          <input
            
            type="text"
            name="phone"
            id="PSphone"
            className="form-control"
            placeholder="Enter Your Mobile Number"
            {...register("phone", { value: PSdetails.Phone })}
          />
          <p className="text-danger"> {errors.phone?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">Address</label>
          <input
            
            type="text"
            name="address"
            id="PSaddress"
            className="form-control"
            placeholder="Enter Your Address"
            {...register("address", { value: PSdetails.Address })}
          />
          <p className="text-danger"> {errors.address?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">City</label>
          <input
            
            type="text"
            name="city"
            id="PScity"
            className="form-control"
            placeholder="Enter Your City"
            {...register("city", { value: PSdetails.City })}
          />
          <p className="text-danger"> {errors.city?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">Country</label>
          <input
            
            type="text"
            name="country"
            id="PScountry"
            className="form-control"
            placeholder="Enter Your Country"
            {...register("country", { value: PSdetails.Country })}
          />
          <p className="text-danger"> {errors.country?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">State</label>
          <input
            
            type="text"
            name="state"
            id="PSstate"
            className="form-control"
            placeholder="Enter Your State"
            {...register("state", { value: PSdetails.State })}
          />
          <p className="text-danger"> {errors.state?.message} </p>
        </div>
        <div className="form-group mt-2">
          <label htmlFor="input-4">Zip/Post code </label>
          <input
            
            type="text"
            name="zip"
            id="PSzip"
            className="form-control"
            placeholder="Enter Your City"
            {...register("zip", { value: PSdetails.Zip })}
          />
          <p className="text-danger"> {errors.zip?.message} </p>
        </div>

        <div className="form-group">
          <button
            type="submit"
            value="Submit"
            className="form-control btn btn-primary px-5 mt-3"
          >
            Update
          </button>
        </div> */}
      </form>
    </div>
  );
}
